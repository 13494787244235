import React, {useEffect, useState, useContext} from 'react';
import { BrowserRouter, Routes, Route, Navigate  } from 'react-router-dom';
import Main from './pages/Main/Main'
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer, toast } from 'react-toastify';
import PontoQr from './pages/PontoQr/PontoQr';


function App() {
  useEffect( () => {
 
  }, [] );


  const defName = "";

  return (    
          <BrowserRouter>
            <ToastContainer autoClose={2000} position='top-center' theme='light' />
            <Routes>
              <Route exact path={defName} element={<Main/>}/>
              <Route exact path={defName+"/ponto_qr/:hash"} element={<PontoQr/>}/>
            </Routes>
          </BrowserRouter>

  );
}

export default App;
