import axios from 'axios';
import { toast } from 'react-toastify';
import {  useNavigate  } from 'react-router-dom';
import moment from 'moment';
import React, {useEffect, useState, useContext} from 'react';

import { AppContext } from '../context';

export const hostApi = 'https://api1.beitec.com.br';
//export const hostApi = 'http://192.168.1.5:3001/';
//export const hostApi = 'http://192.168.1.6:3001/';

export const APP_V = document.querySelector('meta[name="version"]').getAttribute('content');
export const APP_BETA = document.querySelector('meta[name="version-beta"]').getAttribute('content');

function UpdContextOnline({on=true}){
   // const { isOnline, setIsOnline } = useContext(AppContext);
 //   setIsOnline(on)
}

const api = axios.create(
    {

        //timeout: 300000,
        //signal: AbortSignal.timeout(300000),
        baseURL: hostApi,
        validateStatus: function (status) {   
           // toast.warn(status)      
           if( status === 422){
                toast.warn(String(status)+" - Logar novamente!");
                localStorage.setItem("loja_tk","");
                window.location = "/login";
            }
            if(status === 401 ){
                toast.warn(String(status)+" ERRO INTERNO!!");

            }
            if( status === 500 ){
                alert("E500 ‼️🔶‼️ EXCEPTION - INFORME O SUPORTE! 🚩" + new Date().toLocaleString("pt-BR", {}) );
                toast.error("EXCEPTION - INFORME O SUPORTE!");
     
            }
           if(status === 205){
               toast.error('Erro. ');
           //    UpdContextOnline(Online ? true : false)
               return status;         
           }      
           
           if(status > 300){             
          //  UpdContextOnline(Online ? true : false)      
           }

           return status >= 200 && status < 300      
        },
    }
)
export default api;

export const apiFile = axios.create(
    {
        baseURL: hostApi,
        validateStatus: function (status) {         
           if(status === 401 || status === 422 || status === 500 ){
              //  toast.warn(String(status)+" - Logar novamente!");
              //  localStorage.setItem("loja_tk","");
               // window.location = "/login";
            }
           if(status === 205){
              // toast.error('Erro. ');
             //  return status;         
           }       
           return status >= 200 && status < 300      
        },
    }
)


export async function apiLocal(ip, port, cmd, ret: ()=>{}, err: ()=>{}){
    axios.get('http://'+ip+':'+port+'/'+cmd, {
       responseType: 'text'
     }).then((response)=>{
       if(ret){
          
           ret(response.data)
        }
     }).catch((response)=>{
       if(err){
           err(response.data)
        }
     });
   
   /*
   .then(function (response) {
       // aqui acessamos o corpo da resposta:
     
       if(ret){
          
           ret(response.data)
        }
     }).catch(function (response) {
       // aqui acessamos o corpo da resposta:
   
       if(err){
           err(response.data)
        }
     }) */
}

export async function apiLocalPost(ip, port, cmd, data, ret: ()=>{}, err: ()=>{}){
     axios.post('http://'+ip+':'+port+'/'+cmd, data, {
        responseType: 'text'
      }).then((response)=>{
        if(ret){
           
            ret(response.data)
         }
      }).catch((response)=>{
        if(err){
            err(response.data)
         }
      });
    
    /*
    .then(function (response) {
        // aqui acessamos o corpo da resposta:
      
        if(ret){
           
            ret(response.data)
         }
      }).catch(function (response) {
        // aqui acessamos o corpo da resposta:
    
        if(err){
            err(response.data)
         }
      }) */
}
