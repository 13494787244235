import { toast } from 'react-toastify';
import api, { apiFile } from './api';
import mid_1 from '../assets/audio/mid_1';//som de erro
import mid_2 from '../assets/audio/mid_2';//som de erro
import mid_3 from '../assets/audio/mid_3';
import { isMobile } from 'react-device-detect';
import { FiHome,FiShoppingCart,FiLayers,FiHexagon,FiUsers,FiBookOpen,FiFramer,FiAperture,FiDollarSign,
    FiList,FiClock,FiPercent,FiActivity,FiTrendingUp,FiTruck,FiSliders } from 'react-icons/fi';

import icon_library from '../assets/icons/library.png';
    
import { BsBoundingBox } from 'react-icons/bs';
import { GrWorkshop } from 'react-icons/gr';
import { Badge, Tooltip } from 'antd';
import moment from 'moment';
import React, {useEffect, useState, useContext, cloneElement} from 'react';

import { AppContext } from '../context';
import { TbSchool } from 'react-icons/tb';


export function beep(_string)
{                   
    if (_string == "erro")  {
        let snd = new Audio(String(mid_2));
        snd.play();
    }         
    if (_string == "erro2x")  {
        let snd = new Audio(String(mid_2));
        snd.play();
        delay(()=> {snd.play();});
    }     
    if (_string == "ok")  {
        let snd = new Audio(String(mid_1));
        snd.play();
        delay(()=> {snd.play();});
    }                 
    if (_string == "+")  {
        let snd = new Audio(String(mid_3));
        snd.play();
    }     
    if (_string == "+2x")  {
        let snd = new Audio(String(mid_3));
        snd.play();
        delay(()=> {snd.play();},1250);
    }   
    
}     

export function apenasNumeros(_string)
{                                          
    if(_string.match(/^[0-9]+$/) != null){
        return true;
    } else {
        return false;
    }
}    

function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min;
  }


export function geraProt()
{                                          
    let hora = safeStr(moment().format("YYYYMMDDhhmmss"))
    return String(hora) + String(getRandomInt(100, 300)) + String(getRandomInt(10, 90)) + String(getRandomInt(1, 9))
}    


export function strBasic(_string, espaco=false)
{                      
    let a = _string.replaceAll('"',"").replaceAll("'","")
    .replaceAll('¨',"").replaceAll('    '," ").replaceAll('!',"").replaceAll('=',"")
    .replaceAll('*',"").replaceAll('{',"").replaceAll('}',"").replaceAll('[',"").replaceAll(']',"").replaceAll('^',"").replaceAll(':',"")
    .replaceAll('%',"").replaceAll('&',"").replaceAll('(',"").replaceAll(')',"")
    .replaceAll('`',"").replaceAll('´',"").replaceAll('?',"").replaceAll('<',"").replaceAll('>',""); 
    
    if(espaco){
       a = a.replaceAll(' ',"");
    }

    if(String(a).length > 2 && String(a)[0] == "$"){
      
    }else{
      a = a.replaceAll('$',"").replaceAll('+',"").replaceAll('-',"")
    }
    return safeStr(a);
}   

export function apenasValor(_string)
{                      
    let a = _string.replaceAll('.','').replaceAll(',','')                   
    if(a.match(/^[0-9]+$/) != null){
        return true;
    } else {
        return false;
    }
}   

export function retornaApenasNumeros(_string)
{               
    let a = String(_string).replace(/\D/g, '');                           
    return a;
}   



export const focusComponent = (name, sellAll=false) => {
    try{
        const el = document.getElementsByName(name);
        if(el.length === 1){
            el[0].focus();
            if(sellAll){
                try{
                    el[0].select();
                }catch{
                    return;
                }
            }
        }
    }catch{
        alert("Objeto não encontrado!!");
    }
  };

export const selectComponent = (name) => {
    try{
        const el = document.getElementsByName(name);
        if(el.length === 1){
      
            el[0].select();
        }
    }catch{
        alert("Objeto não encontrado!");
    }
};

export const focusComponentPorID = (id, sellAll=false) => {
    try{
        const el = document.getElementById(id);
        if(el){
            el.focus();
            if(sellAll){
                el.select();
            }
        }
    }catch{
        alert("Objeto não encontrado!");
    }
};

export const selectComponentPorID = (id) => {
    try{
        const el = document.getElementById(id);
        if(el){
            el.select();
        }
    }catch{
        alert("Objeto não encontrado!");
    }
};

export const isFocusedComponentPorID = (id) => {
    try{
        const el = document.getElementById(id);
        if(el){
            if(el === document.activeElement){
                return true
            }
        }
    }catch{
        //-
    }
    return false
};

  

export async function delay( func = () => {} , tempo=350){
    setTimeout(func, tempo);
};

export function userPerm(perm="", api=false, callback=()=>{}){
    
    let res = false;
    if(String(perm).length > 1){
        let tmp_perm = "";
        try{
            tmp_perm = String(localStorage.getItem("user_perm"));
        }catch{
            tmp_perm = ""
        }
        tmp_perm = safeStr(tmp_perm);
        if(tmp_perm.length > 2){
            let list = tmp_perm.split(";")
            if(list.indexOf(perm) >= 0 || list.indexOf("ADMIN") >= 0){
                res = true;
            }
        }

        if(api && res == true){  
            asyncGet("userPerm", { perm:safeStr(perm) }, (e) => { 
                callback(e);            

            }, false);
        }else{
            if(res){
                callback('1');  
            }else{
                callback('0');  
            }
             
        }    
    }
    return res;
};

export function userPermAutoClose(perm=""){

    userPerm(perm, true, (e)=>{  if(e == '0'){ window.location = "/"; }});
}



export const handleEnter = (e, next = null) => {
    if (e.key == "Enter" || e.key == "Next" || e.key == "Go" || e.key == "Okay" || e.key == "Ok") {
        
        if(next){
            e.preventDefault();
            focusComponent(next, true);
        }
    }

  };

export function getLocalStorage(tipo="", isArray: boolean = false)
{      
    let obj = {};   
    if (isArray){
        obj = [];
    }                                 
    if(tipo.length > 1){
        if(tipo == "ch_users"){
            let a = localStorage.getItem('ch_users');
            if(a && a != null && String(a).length > 10){
                obj = JSON.parse(a);   
            }else{
               // alert(JSON.stringify(obj));
                asyncGet("getUsers", { }, (e) => { 
      
                    localStorage.setItem('ch_users', JSON.stringify(e.users));
                    obj = e.users;          
                }, false);
            }
        }
        if(tipo == "aaa"){
            
        }
    }
    return obj;
}     

export function safeStr(_string, def="")
{         

    let ret = def;                                    
    if(_string != null || _string != undefined || _string != NaN){
        if( String(_string).length > 0 ){
          
            if( String(_string).toUpperCase() == "NONE" || 
                String(_string).toUpperCase() == "NULL" || 
                String(_string).toUpperCase() == "NaN" || 
                String(_string).toLowerCase() == 'undefined'){
                 
            }else{
                ret = String(_string);    
            }
   
        }

    }
    return ret;
}     

export function safeInt(_string, def=0)
{         
    let ret = def;   
                               
    if(isNaN(_string) == false || _string != null || _string != NaN || _string != undefined ){
            let str = String(_string); 
            try{
                if(str == "0"){
                    ret = 0;
                }else{
                    if(str.split(".").length - 1 >= 1){
                        ret = parseInt(str.split(".")[0])||def;;
                    }else if (str.split(",").length - 1 >= 1 ){
                        ret = parseInt(str.split(",")[0])||def;
                    }else{            
                        ret = parseInt(_string)||def;
                    }
                }
            }
            catch(err) {
                ret = def
            }         
    }else{
        if(_string == '' || String(_string).length <= 0){
            ret =  def;   
        }else{
            let str = String(_string);
            if(str == "0"){
                ret = 0;
            }else{
                ret = parseInt(_string) || def;   
            }
        }      
    }
    return ret;
}   

export function safeFloat(_string, def=0.0)
{         
    let ret = def;                                  
    if(isNaN(_string) || _string != null || _string != NaN || _string != undefined ){       
        ret = parseFloat(_string) || def;
    }else{
        if(_string == '' || String(_string).length <= 0){
            ret =  def;   
        }else{
            ret =  parseFloat(_string);   
        }      
    }
    return ret;
}  


export function isObjectEmpty(obj) { // Verifica se o obj está vazio, True para vazio, false para cheio.
    if(Object.keys(obj).length === 0){ // 9788000121451
        return true;
    }else{
        let tt = String(JSON.stringify(obj));
       
        if(tt.length >= 2 && tt.length <= 8){
           
            tt = String(tt).replaceAll(" ", "");
            
            if(tt == "{}" || tt == "[]" || tt == "{[]}" || tt == "[{}]" || tt == "{{}}" || tt == "[[]]" || tt == "[{{}}}"){
      
                return true;
            }else{
                return false;
            }
        }else{
            if(String(obj).length > 2){
                return false;
            }else{
                return true;
            }            
        }               
    }  
  }

export function safeBol(_boolean, def=false)
{         
    let ret = def;                                 
    if(_boolean != null){
        if( _boolean === "1" ){        
            ret = true;    
        }else{
            if( _boolean === 1 ){        
                ret = true;    
            }else{
                if( _boolean === true ){        
                    ret = true;    
                }
            }
        }
   
    }
    return ret;
}    

export function getInGlobal(_string="")
{
    let res = "";
    if(_string.length > 0){
       let obj = JSON.parse(localStorage.getItem("cfg_global"));
       if(obj && obj[_string]){
        res = String(obj[_string]);
       }else{
           res =  String(_string.replaceAll("_"," ")).toUpperCase();
       }
    }
    
    return res;
}


export function converteMovProdTipo(tipo){
    let cor = "yellow";
    let tipoTx = "";
    let icon = "0";
    if(String(tipo).length == 3){
        if (tipo[0] == "1") cor = "green";   
        if (tipo[0] == "2") cor = "red"; 
        if (tipo[0] == "3") cor = "blue";
        
        icon = String(tipo)[0]; // 1 up / 2 down / 3 igual
    
        if (tipo == "100") tipoTx = "Entrada Manual";   
        if (tipo == "101") tipoTx = "Alim Compra"; 
        if (tipo == "102") tipoTx = "Alim Consig";  
        if (tipo == "103") tipoTx = "Entrada de Ajuste";  
        if (tipo == "104") tipoTx = "Entrada de estorno";  
        if (tipo == "105") tipoTx = "Entrada Outras";  

        
        if (tipo == "106") tipoTx = "Ent. Dev. de Consig";  
        if (tipo == "107") tipoTx = "Ent. Ret. Vend. Fora. Estab.";  


        if (tipo == "200") tipoTx = "Venda Caixa";
        if (tipo == "201") tipoTx = "Venda fora do Caixa";
        if (tipo == "202") tipoTx = "Saída Devolução de Compra";
        if (tipo == "203") tipoTx = "Saída Devolução de Consig";
        if (tipo == "204") tipoTx = "Saída de Ajuste";
        if (tipo == "205") tipoTx = "Saída de Estorno";
        if (tipo == "206") tipoTx = "Outras Saídas";

        if (tipo == "207") tipoTx = "Envio de Consig";
        if (tipo == "208") tipoTx = "Saida Venda fora do Estab.";

        if (tipo == "215") tipoTx = "Dev. Simb (sem mov estoque)";

        if (tipo == "300") tipoTx = "Ajuste";
 
    }
    return { 'tx':tipoTx, 'cor':cor, 'icon': icon }
}

export function windowOpen(url){
    if (window.matchMedia('(display-mode: standalone)').matches) {                                        
        window.open(url, "_blank", 
        { width: 400, height: 400, toolbar: "no", menubar: "no", status: "no",
        scrollbars: "yes", resizable: "yes"
        });
    }else{
        window.open(url, "_blank");
    }
}

export function converteMovProdConsigTipo(mov_tipo){
    let cor = "yellow";
    let tipoTx = "";
    let icon = "0";
    let tipo = String(mov_tipo);
    if(String(tipo).length == 2){
        if (tipo == "10" || tipo == "11" || tipo == "20" || tipo == "21"){
            cor = "green";   
            icon = "1"; // 1 up / 2 down / 3 igual
        } 
        if (tipo == "12" || tipo == "13" || tipo == "14" || tipo == "22" || tipo == "23" || tipo == "24") {
            cor = "red";   
            icon = "2"; // 1 up / 2 down / 3 igual
        } 
  
    
        if (tipo == "10") tipoTx = "Entrada";   
        if (tipo == "11") tipoTx = "Entrada sem Estoque"; 
        if (tipo == "12") tipoTx = "Acerto";  
        if (tipo == "13") tipoTx = "Devolução";  
        if (tipo == "14") tipoTx = "Devolução sem Estoque";   

        if (tipo == "20") tipoTx = "Envio";   
        if (tipo == "21") tipoTx = "Envio sem Estoque"; 
        if (tipo == "22") tipoTx = "Acerto";  
        if (tipo == "23") tipoTx = "Devolução";  
        if (tipo == "24") tipoTx = "Devolução sem Estoque";  

    }
    // toast.success(tipo)
    return { 'tx':tipoTx, 'cor':cor, 'icon': icon }
}


export function updStateObject(v_object=[], setList, call)
{
    let clone = v_object;  
    // (fMaip) => fMaip.map( e =>     
    clone.map( call );
   //clone.map( e => { if(e.key == key){ e={...e, qtd:25}; }});
   setList(clone);
   return clone;
}



/*
export function updStateJson(v_json=[], setList, call)
{
   let clone = JSON.parse(JSON.stringify(v_json));  
   clone.map( call );
   setList(clone);
   return clone;
}
*/
export function updStateJson(v_json=[], setList, call, callback=()=>{})
{

   let clone = []

   try{
        //alert(JSON.stringify(v_json)); 
        clone =  cloneElement({}, {}, v_json).props.children; //structuredClone(v_json); // pode ser incompativel com navegadores mais antigos. 
       // alert(JSON.stringify(clone)); 
   }catch(err){
      // alert(err.message)
      clone = []
   }finally{
        setList(clone);
        clone.map( call );
        if(callback){
            callback();
        }

   }

   return clone;
}

export function updStateJsonOne(v_json={}, setList, col="", value="")
{
   let clone = JSON.parse(JSON.stringify(v_json));  
   // (fMaip) => fMaip.map( e =>     
   clone[col] = value;
   //clone.map( e => { if(e.key == key){ e={...e, qtd:25}; }});
   // setList(clone);
   return clone;
}

export async function asyncGet(func="", objs={}, sucess=()=>{}, silent=false, loand=()=>{}, err=()=>{}, isOffline=false){
    // asyncGet("func", {objs}, ()=>{sucesso});
    if(loand){ loand(true); } 
    if(isOffline){
        //toast.warn("OFF")
        if(loand){ loand(false); }
        err('OFFLINE');
    }else{   
        try {           
            const resp = await api.post(`/`+func, objs, {headers: {'Content-Type': 'application/json', Authorization: 'Bearer '+localStorage.getItem("loja_tk")}});    
            if(resp.data.err == 0){  
                if(func == "prodGet"){
                   // alert(JSON.stringify(resp.data))
                }
               
              if(loand){ loand(false); }            
              sucess(resp.data.res);
            }else{
              if(loand){ loand(false); }
              if(!silent){
                toast.warn(resp.data.err_tx);
              }
   
              err(resp.data);
            }
          }catch(e){
             // UpdContextOnline(Online ? true : false);
              //setIsOnline(Online ? true : false)
              // alert(e.message)

            console.log("EXCEPT::"+String(e.message))

              if(loand){ loand(false); }

             
              err('EXCEPT');
      
      
            if(!silent){
              toast.error('Erro. ao carregar . '+e);
            }
          } 
    }
   
   // if(loand){ loand(false); }
  }


  export async function asyncGetTrue(func="", objs={}, sucess=()=>{}, silent=false, loand=()=>{}){
    if(loand){ loand(true); }
    try {           
      const resp = await api.post(`/`+func, objs, {headers: {'Content-Type': 'application/json', Authorization: 'Bearer '+localStorage.getItem("loja_tk")}});    
      if(resp.data.err == 0){  
        if(loand){ loand(false); }                    
        sucess(resp.data.res);
      }else{
        if(loand){ loand(false); }
        sucess(resp.data);
      }
    }catch(e){
        if(loand){ loand(false); }
        sucess('EXCEPT');
    } 

  }



export async function asyncSendFile(func="", objs=null, sucess=()=>{}, silent=false, loand=()=>{}, err=()=>{}){
    // asyncGet("func", {objs}, ()=>{sucesso});
    if(loand){ loand(true); }
    try {           
      const resp = await apiFile.post(`/`+func, objs, {headers: {'Content-Type': 'multipart/form-data', Authorization: 'Bearer '+localStorage.getItem("loja_tk")} });    
      if(resp){  
   
        if(loand){ loand(false); }                    
        sucess(resp.data.res);
      }else{
        if(loand){ loand(false); }
        if(!silent){
          toast.warn(resp.data.err_tx);
        }
        err(resp.data);
      }
    }catch(e){
      //  UpdContextOnline(Online ? true : false);
      if(loand){ loand(false); }
      if(!silent){
        toast.error('Erro. ao carregar . '+e);
      }
    } 
    
  }

  export async function asyncGetFile(func="", objs=null, sucess=()=>{}, silent=false, loand=()=>{}, err=()=>{}){
    // asyncGet("func", {objs}, ()=>{sucesso});
    if(loand){ loand(true); } //'application/json' arraybuffer / blob
    try {           
      const resp = await apiFile.post(`/`+func, objs, {responseType: 'blob', headers: {'Content-Type': 'multipart/form-data', Authorization: 'Bearer '+localStorage.getItem("loja_tk")} }); 
      if(resp.data){      
        if(loand){ loand(false); }                 
        let tt = String(resp.headers["content-disposition"]);   
        if(tt.length > 10){
            tt = safeStr(tt.split("filename=")[1]);
        }else{
            tt = '';
        }
        sucess(resp.data, tt, resp.headers["content-length"]);
      }else{
        if(!silent){
          toast.warn("Erro ao baixar arquivo.");
        }
        err(resp.data);
      }
    }catch(e){
       // UpdContextOnline(Online ? true : false);
      if(!silent){
        toast.error('Erro. ao carregar . '+e);
      }
    } 
    if(loand){ loand(false); }
  }

export function bolToInt(_boolean, def=0)
{         
    let ret = def;     
                          
    if(_boolean != null){
        if( _boolean === true ){        
            ret = 1;    
        }
   
    }
    return ret;
}    

export function safeQtd(_string, def=0)
{         
    let ret = def;   
    let decDigs = 0;
    let str = safeStr(_string, def=String(def));     
    if(str != null && str.length > 0){  
            if( str.includes(".") && str.includes(",") ){
       
                if( str.indexOf(".") > str.indexOf(",") ){ // 00,00.00
                    str = String(str).replace(',', '')
                }
                if( str.indexOf(".") < str.indexOf(",") ){ // 00.00,00
                    str = String(str).replace('.', '').replace(',', '.')
                }             
            }else{

                if( str.includes(".") ) {
                    // OK
                
                } else if( str.includes(",") ) {   
                
                    str = String(str).replace(',', '.')
                } else {
                    // OK
                    
                } 
            }
            if( str.includes(".") ) {
                decDigs = 3;
                
            }
            if(decDigs >= 1){
                ret = parseFloat(parseFloat(str).toFixed(decDigs)); 
            }else{
                ret = parseFloat(str); 
            }            
    }
    return ret;
}   


export function realToFloat(_real:string, def:number=0.0, decDigs:number=2)
{         

    let ret:number = def;     
    let str = safeStr(_real, def="");   
                        
    if(str != null && str.length > 0){  
        /* 
        12.500,95
        25.159,954
           520,50
           520,514

        1000.00
        1000.512
       2,100.21
       */
      /*
        let virgulas = str.split(",").length-1; 
        let pontos = str.split(".").length-1; 

        if(virgulas == 1 && pontos == 0){
            ret = parseFloat(parseFloat(String(str).replace(',', '.')).toFixed(2));   
        }else if(virgulas == 0 && pontos == 1){
            ret = parseFloat(parseFloat(String(str)).toFixed(2)); 
        }else if(virgulas == 1 && pontos >= 2){
            ret = parseFloat(parseFloat( String(str).replace('.', '').replace(',', '.')).toFixed(2));  
        }else if(virgulas >= 2 && pontos == 1){
            ret = parseFloat(parseFloat( String(str).replace(',', '')).toFixed(2));  
        }else if(virgulas == 1 && pontos == 1){
            ret = parseFloat(parseFloat( String(str).replace('.', '').replace(',', '.')).toFixed(2)); 
        }else if(virgulas == 0 && pontos == 0){
            ret = parseFloat(parseFloat( String(str)).toFixed(2)); 
        }
       */
        if(String(str).includes(",")){
            if(decDigs > 0){
                ret = parseFloat(parseFloat( String(str).replace('.', '').replace(',', '.')).toFixed(decDigs)); 
            }else{
                ret = parseFloat(String(str).replace('.', '').replace(',', '.')); 
            }
            
        }else{
            if(decDigs > 0){
                ret = parseFloat(parseFloat(String(str)).toFixed(decDigs)); 
            }else{
                ret = parseFloat(String(str)); 
            }
        }  
      // ret = parseFloat(parseFloat( String(str)).toFixed(2));  
    }

    return ret;
}    

export function arredondarDec(_real:number, def:number=2)
{     
    if (_real < 0.0) { _real = 0.0 } 
    let ret:number = parseFloat(parseFloat(_real).toFixed(2));                       
    return ret;
}    

export function floatToReal(_real:number, decDigs=2,  dec:string="", decPos:string="", semZeros=false ,deb:boolean=false, )
{       
    //_real = arredondarDec(_real);
   // if(deb){alert("A:"+_real); }  

   // if (typeof _real === "string") {
  

    let ret:string = _real.toLocaleString('pt-br', {maximumFractionDigits: decDigs, minimumFractionDigits: decDigs});
 
    if(ret.length <= 0){
        if(decDigs === 1){
            ret = "0,0"
        }else if(decDigs === 2){
            ret = "0,00"
        }else if(decDigs === 3){
            ret = "0,000"
        }else if(decDigs === 4){
            ret = "0,0000"
        }else{
            ret = "0"
        }       
    }
    if(deb){alert("R:"+ret); }      
    
    if(String(dec).length > 0){
        if(String(decPos).length > 0){
            return String(dec)+ret+String(decPos);
        }else{
            if(semZeros==true &&  (decDigs == 2 && ret.includes(",00")) || (decDigs == 3 && ret.includes(",000"))){
                return String(ret).replace(",000","").replace(",00","");
            }else{
                return ret;
            }        
        }  
    }else{
        if(String(decPos).length > 0){
            return ret+String(decPos);
        }else{
            if(semZeros==true && ( (decDigs == 2 && ret.includes(",00")) || (decDigs == 3 && ret.includes(",000")))){
                return String(ret).replace(",000","").replace(",00","");
            }else{
                return ret;
            }      
        } 
    }  
}    



export function findStrInList(lista, busca_col, busca_valor, res_col)
{
  
    let res = "";
    let oob = lista.find((e) => e[busca_col] == busca_valor);
    if(oob){
        res = safeStr(oob[res_col]);

    }
    return res;
}


export function findIntInList(lista, busca_col, busca_valor, res_col, def=-1)
{
    let res = def;
    let oob = lista.find((e) => e[busca_col] == busca_valor);
    if(oob){
        res = parseInt(oob[res_col]);

    }
    return res;
}

export function findInList(lista, busca_col, busca_valor, res_col, def={})
{
    let res = def;
    let oob = lista.find((e) => e[busca_col] == busca_valor);
    if(oob){
        if(res_col == "$"){
            res = oob;
        }else{
            res = oob[res_col];
        }
    }
    return res;
}

export function chaveToNumero(chave="", comSerie=true )
{
    let vChave = retornaApenasNumeros(chave);
    let vSerie = "";
    let vNum = "";
    let res = "";
    if(vChave.length == 44){
        vChave = vChave.slice(22,34);
        vSerie = vChave.substring(0,3)
        vNum = vChave.substring(3,12)
        // -----------------------------------------
        let inicio = true;
        let tmp = ""
        for (let index = 0; index < vSerie.length; index++) {
            if(inicio){
                if(vSerie[index] != "0"){
                    inicio = false;
                    tmp += String(vSerie[index]);
                }  
            }else{
                tmp += String(vSerie[index]);
            }                
        }
        vSerie = tmp;
        // -----------------------------------------
        inicio = true;
        tmp = ""
        for (let index = 0; index < vNum.length; index++) {
            if(inicio){
                if(vNum[index] != "0"){
                    inicio = false;
                    tmp += String(vNum[index]);
                }  
            }else{
                tmp += String(vNum[index]);
            }                
        }
        vNum = tmp;
        // -----------------------------------------

        if(comSerie){
            res = vNum+"-"+vSerie
        }else{
            res = vNum
        }
    }
    return res;
}

export function Icon({arq="", size="28px", marginRight="0px", marginLeft="0px", modoGif=false, animado=false, hint="" })
{
    if(hint.length > 0){
        
        return( modoGif ? <Tooltip title={hint}><img src={arq ? arq : ""} style={{height:size, width:size,
            marginRight:marginRight, marginLeft:marginLeft, 
            mixBlendMode: "multiply" }} /></Tooltip> : 
            <Tooltip title={hint}><img className={animado ? 'icon-anim' : ''}  src={arq ? arq : ""} style={{height:size, width:size, marginRight:marginRight, marginLeft:marginLeft, 
            }} /></Tooltip> );
    }else{
        return( modoGif ? <img src={arq ? arq : ""} style={{height:size, width:size,
            marginRight:marginRight, marginLeft:marginLeft, 
            mixBlendMode: "multiply"}} /> : 
            <img className={animado ? 'icon-anim' : ''} src={arq ? arq : ""} style={{height:size, width:size, marginRight:marginRight, marginLeft:marginLeft, 
            }} /> );
    }
}


export const SideAd = () => {
    useEffect(() => {
        /*
      const pushAd = () => {
        try {
          const adsbygoogle = window.adsbygoogle
          console.log({ adsbygoogle })
          adsbygoogle.push({})
        } catch (e) {
          console.error(e)
        }
      }
  
      let interval = setInterval(() => {
        // Check if Adsense script is loaded every 300ms
        if (window.adsbygoogle) {
          pushAd()
          // clear the interval once the ad is pushed so that function isn't called indefinitely
          clearInterval(interval)
        }
      }, 300)
  
      return () => {
        clearInterval(interval)
      }*/
    }, [])
    
    return (<>
    {/* 
      .._..
      <ins
        className="adsbygoogle"
        style={{ display: "inline-block", width: "300px", height: "250px" }}
        data-ad-client="ca-pub-1969537521124215"
        data-ad-slot="8144893990"
        data-ad-format='auto' 
        data-full-width-responsive="true"
      ></ins>
    */}
    </>)
  }
  
    

export function getApp(cod="")
{
    let icon = <></>;
    let nome = "";
    let link = "";
    let tipo = 0;
    if(cod == "A-ORP-952"){
        icon = <GrWorkshop size={24}/>;
        nome ="Ordem de Produção";
        link ="/producoes";
        tipo = 1;
    }
    if(cod == "A-PDS-715"){
        icon = <Badge count={3}><BsBoundingBox size={24}/></Badge>;
        nome ="Pedidos dos Sites";
        link ="/pedidosSite";
        tipo = 1;
    }
    if(cod == "A-ENC-752"){
        icon = <FiTruck size={24}/>;
        nome ="Encomendas";
        link ="/encomendas"; tipo = 1;
    }
    if(cod == "A-CON-852"){
        icon = <FiHexagon size={24}/>;
        nome ="Consignados";
        link ="/consignados"; tipo = 1;
    } 
    if(cod == "C-FCH-211"){
        icon = <FiBookOpen size={24}/>;
        nome ="Ficha";
        link ="/ficha"; tipo = 2;
    }
    if(cod == "A-MEN-115" || cod == "A-ECO-414"){
        icon = <FiAperture size={24}/>;
        nome ="Sites";
        link ="/sites"; tipo = 1;
    }
    if(cod == "A-PGR-501"){
        icon = <FiDollarSign size={24}/>;
        nome ="Financeiro";
        link ="/financeiro"; tipo = 1;
    }
    if(cod == "A-LIS-224"){
        icon = <FiList size={24}/>;
        nome ="Listas";
        link ="/listas"; tipo = 1;
    }
    if(cod == "A-AGE-442"){
        icon = <FiClock size={24}/>;
        nome ="Agenda";
        link ="/agenda"; tipo = 1;
    }
    if(cod == "C-GIF-664"){
        icon = <FiPercent size={24}/>;
        nome ="Gift Cards";
        link ="/giftCard"; tipo = 2;
    }
    if(cod == "A-PRO-653"){
        icon = <FiActivity size={24}/>;
        nome ="Relatórios Pró";
        link ="/relatoriosPro"; tipo = 1;
    }
    if(cod == "C-ORC-545"){
        icon = <FiHome size={24}/>;
        nome ="Orçamentos";
        link ="/orcamentos"; tipo = 2;
    }
    if(cod == "A-BLB-998"){
        icon = <Icon arq={icon_library} size={"30px"}/>
        nome ="Bblioteca";
        link ="/biblioteca"; tipo = 1;
    }
    //-----------------------------------------------

    if(cod == "CAIXA" || cod == "VENDA" || cod == "VENDER"){
        icon = <FiShoppingCart size={24}/>;
        nome ="Venda (caixa)";
        link ="/caixa"; tipo = 1;
    }

    if(cod == "ESTOQUE"){
        icon = <FiLayers size={24}/>;
        nome ="Estoque";
        link ="/estoque"; tipo = 1;
    }
    if(cod == "PESSOAS"){
        icon = <FiUsers size={24}/>;
        nome ="Pessoas e Empresas";
        link ="/pessoasEmpresas"; tipo = 1;
    }
    if(cod == "NOTAS"){
        icon = <FiFramer size={24}/>;
        nome =" Gerenciador Fiscal";
        link ="/notasFiscais"; tipo = 1;
    }
    if(cod == "COBRAR"){
        icon = <FiDollarSign size={24}/>;
        nome ="Cobrar";
        link ="/cobrar"; tipo = 1;
    }
    if(cod == "CAIXAS"){
        icon = <FiActivity size={24}/>;
        nome ="Controle de Caixas";
        link ="/caixas"; tipo = 1;
    }
    if(cod == "RELATORIOS"){
        icon = <FiTrendingUp size={24}/>;
        nome ="Relatórios";
        link ="/relatorios"; tipo = 1;
    }
    if(cod == "TUTORIAIS"){
        icon = <TbSchool size={24}/>;
        nome ="Tutoriais";
        link ="/tutoriais"; tipo = 1;
    }
    if(cod == "REVISAO"){
        icon = <FiTrendingUp size={24}/>;
        nome ="Revisão de Estoque";
        link ="/revisao"; tipo = 1;
    }
    
    if(cod == "PEDFORN"){
        icon = <FiHome size={24}/>;
        nome ="Pedidos Forn.";
        link ="/orcamentos"; tipo = 1;
    }

    return { icon:icon, nome:nome, link:link, cod:cod, tipo:tipo };
}

