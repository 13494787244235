import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { ConfigProvider } from "antd";
import './antd.css';
import ptBR from 'antd/locale/pt_BR';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ContextProvider from './context';

import "./_conf.scss";

ReactDOM.render(
  <React.StrictMode>
    <ContextProvider>   
      <ConfigProvider locale={ptBR}>
        <App />
      </ConfigProvider>   
    </ContextProvider>     
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
