import './style.scss'
import React, {useEffect, useState,useContext, useRef, Children, useCallback} from 'react';
import { AppContext } from '../../context';
//import { toast } from 'react-toastify';
//import api from '../../services/api';
import { FiSearch,FiEdit2, FiPackage, FiMoreHorizontal,FiDisc } from 'react-icons/fi';
import { AiFillSetting, AiOutlineLeft } from 'react-icons/ai';
import { Bs123, BsCollectionFill, BsFillSendFill } from 'react-icons/bs';
import throttle from 'lodash/throttle';
import icon_load from '../../assets/icons/bat.gif';
import ReactGA from "react-ga4";
import { Icon, apenasNumeros, asyncGet, delay, floatToReal, focusComponent, handleEnter, isFocusedComponentPorID, isObjectEmpty, realToFloat, safeFloat, safeInt, safeStr, selectComponent, selectComponentPorID, strBasic, updStateJson, updStateJsonOne } from '../../services/utils';
import moment from 'moment';
import { LoadingOutlined } from '@ant-design/icons';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { InputNumber, DatePicker, Spin, Result } from 'antd';

import { Tag, Button, Select } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import EmojiPicker from 'emoji-picker-react';

import { AiOutlineRight } from "react-icons/ai";
import { VscOpenPreview, VscFilter, VscSearch } from "react-icons/vsc";

import { format } from 'path';
import { toast } from 'react-toastify';
import aten_a from '../../assets/icons/aten_a.gif';
import { Table, Input, Tooltip  } from 'antd';
import { SearchOutlined, WhatsAppOutlined, MailOutlined, DeleteOutlined, DeleteFilled, LikeOutlined, LikeFilled ,
        CaretUpOutlined , CaretDownOutlined   } from '@ant-design/icons';
import { Alert } from 'antd';

import { Link } from 'react-router-dom';

import dayjs from 'dayjs';

import { randomInt, randomUUID } from 'crypto';

import { isMobile } from 'react-device-detect';
import { retornaApenasNumeros } from '../../services/utils';
import { FaQuestionCircle, FaTag } from 'react-icons/fa';
import { FaWandMagicSparkles } from 'react-icons/fa6';


const { Option } = Select;

function randomNum(max) {
    let min = 1;
    // 👇️ get number between min (inclusive) and max (inclusive)
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  function randomNumStr(max) {
    return String(randomNum(max))
  }

    export const BContext = ({ show=null, setShow=()=>{}, itens=[], icones=false, info, closeOnClick=true }) => {
        const { isMobile, isTablet } = useContext(AppContext);
        const [pos, setPos] = useState({x: 0, y: 0});

        const [topo, setTopo] = useState(false);
        const [page, setPage] = useState(1);


        const [subItens, setSubItens] = useState([]);
        
        useEffect(() => {
            if(topo == true){
                setTopo(false);
            }
        if(itens.length > 0){
            itens.map( e => { if(e != null && isObjectEmpty(e) == false) { if(e.tipo == "TOPO"){ setTopo(true) } }});
        }
        

        }, [itens]);

        

        useEffect(() => {
            setPage(1);
            setSubItens([]);
            if(show != null){
            let x = show.clientX;
            let y = show.clientY;
            

            if( (x + 250) >= window.innerWidth){
                x = x - 200;
            }

            let q = 0;
            itens.map( e => { if(e != null && isObjectEmpty(e) == false) { if(e.tipo == "ITEM"){q++} } });

            if(q > 0){
                if( (y + (q * 35)) >= window.innerHeight){
                    y = y - (q * 35);
                }
            }

            setPos({
                x: x,
                y: y
                });
            
            }else{
                
            }
        }, [show]);

        function addItem(tipo, nome ="", hint = "", click = () => {}, check, icon, disabled, subs=[], visible = true, help=""){

            function convTxIcon(_icon){
                if(_icon && String(_icon)[0] == "_"){
                    let tmp_icon = <></>
                    if(String(_icon) == "_MAGIC"){ tmp_icon = <FaWandMagicSparkles color='#717ced'/> }
                    if(String(_icon) == "_ETIQUETA"){ tmp_icon = <FaTag color='#50799e'size={18}/> }
                    if(String(_icon) == "_ENVIAR"){ tmp_icon = <BsFillSendFill color='#50799e'size={18}/> }
                    if(String(_icon) == "_MENU_CX"){ tmp_icon = <BsCollectionFill color='#50799e'size={18} /> }
                    return tmp_icon;
                }else{
                    return _icon;
                }
            }

            if(visible){
            if(tipo == "SUBS") {
                return(<div className={'bcontext-item '+(disabled && disabled == true ? "bcontext-disabled" : "")} 
                            onClick={()=>{ if(disabled && disabled == true){ }else{ setSubItens(subs); setPage(2); } }} 
                            style={{paddingLeft: icones ? "2px" : null, justifyContent:"space-between"}}>
                {icones ? <div style={{display:"flex", alignItems:"center"}}><div style={{width:"22px", marginRight:"4px", display:"flex", justifyContent:"center", alignItems:"center",
                                        fontSize:"20px",  }}>{icon ? convTxIcon(icon) : ""}</div>{nome}</div> : <>{nome}</>}  
                <AiOutlineRight color='black'/>
                </div>)
            }else if(tipo == "TOPO") {
                return(<div className={'bcontext-ite-topo '+(disabled && disabled == true ? "bcontext-disabled" : "")}
                            onClick={()=>{if(disabled && disabled == true){ }else{ click(); if(closeOnClick){ setShow(null) } } }} 
                            style={{paddingLeft: icones ? "2px" : null, justifyContent:"space-between"}}>
                    {<div style={{width:"22px", marginRight:"4px", display:"flex", justifyContent:"center", alignItems:"center",
                        fontSize:"20px",  }} title={nome} >{icon ? convTxIcon(icon) : nome}</div>}  
            
                </div>)
            }else if(tipo == "ITEM") {
                return(<div className={'bcontext-item '+(disabled && disabled == true ? "bcontext-disabled" : "")} 
                            onClick={()=>{ if(disabled && disabled == true){ }else{ click(); if(closeOnClick){ setShow(null) } } }} 
                            style={{paddingLeft: icones ? "2px" : null, justifyContent:"space-between"}}>
                {icones ? <div style={{display:"flex", alignItems:"center"}}>
                                <div style={{width:"22px", marginRight:"4px", display:"flex", justifyContent:"center", alignItems:"center",
                                        fontSize:"20px",  }}>{icon ? convTxIcon(icon) : ""}</div>{nome}</div> : <>{nome}</>}  
                {String(hint.length) > 0 && <div style={{fontSize:"11px", color:"rgb(55, 55, 55)", marginRight:"5px"}}>{hint}</div>}
                {String(help).length > 0 && <div><Tooltip title={String(help)} zIndex={999999}><FaQuestionCircle size={14} color={"rgb(69, 106, 121)"} style={{marginLeft:"5px"}}/></Tooltip></div> }

            
                </div>)
            }else if(tipo == "CHECK") {
                return(<div className={'bcontext-item '+(disabled && disabled == true ? "bcontext-disabled" : "")} onClick={()=>{
                    if(disabled && disabled == true){ }else{click(); if(closeOnClick){ setShow(null) } } }}>
                <BCheck titulo={nome} modelo={4} textPos='end' fundo='rgba(255, 255, 255, 0)' value={check && check == true ? true : false} 
                        setValue={()=>{}}  />
                </div>)
            }else if(tipo == "-"){
                return(<div className='bcontext-separador'/>)
            }
            }
        }

        return(<>
            { show != null && <>
            <div style={{position:"fixed",top:0,left:0, zIndex:9999, height: "100%", width:"100%"}} 
                onClick={()=> {setShow(null)}} onContextMenu={(e)=>{ e.preventDefault(); setShow(null); }}  >
            
            </div>
            <div className='bcontext-fundo' style={{top: pos.y, left: pos.x,  
                                                    width: (itens.length > 16 && isMobile == false ) ? "420px" : "auto" }}>
                { topo == true && <>
                <div style={{ display:"flex", flexDirection:"row", height:"30px", alignItems:'center', gap:"5px" }}>
                    {   itens.map( (e, index) => { if(e != null && isObjectEmpty(e) == false) { if(e.tipo == "TOPO"){
                                return(addItem(e.tipo, e.nome, e.hint, e.click, e.check, e.icon, e.visible, e.help));
                        }}})
                    }
                </div>
                <div className='bcontext-separador'/>
                </> }
                <div style={{ display:"flex", flexDirection:"row"}}>
                    { page == 1 && <>
                        { isMobile == true ? 
                            <div className='bcontext-itens' >
                            { itens.map(( (e, index) => { if(e != null && isObjectEmpty(e) == false) {if(e.tipo != "TOPO"){
                                return(addItem(e.tipo, e.nome, e.hint, e.click, e.check, e.icon, e.disabled, e.subs, e.visible, e.help));                
                            }}})) }
                            </div>
                        : <>
                            <div className='bcontext-itens' >
                            { itens.map(( (e, index) => {  if(e != null && isObjectEmpty(e) == false) {if(index <= 16 && e.tipo != "TOPO"){
                                return(addItem(e.tipo, e.nome, e.hint, e.click, e.check, e.icon, e.disabled, e.subs, e.visible, e.help));                
                            }}})) }
                            </div>
                            { itens.length > 16 && isMobile == false &&  <>
                            <div className='bcontext-itens' style={{borderLeft:"1px solid rgb(224, 224, 224)"}} >
                                { itens.map(( (e, index) => { if(index > 16 && e.tipo != "TOPO"){
                                    return(addItem(e.tipo, e.nome, e.hint, e.click, e.check, e.icon, e.disabled, e.subs, e.visible, e.help));         
                            }})) }
                            </div>
                            </> }
                        </>}
                    </> }
                    { page == 2 && <>
                        <div className='bcontext-itens' style={{padding:"2px", }} >
                            <div className='bcontext-itens' style={{ outline:"1px solid rgb(218, 218, 218)", borderRadius: "10px", padding: "4px"}} >
                                <div className={'bcontext-item'} onClick={ () => {setPage(1)}} >
                                <AiOutlineLeft color='black' style={{marginRight:"10px"}}/> Voltar
                                </div>
                                <div className='bcontext-separador'/>
                                { subItens.map(( (e, index) => { if(e.tipo != "TOPO"){
                                    return(addItem(e.tipo, e.nome, e.hint, e.click, e.check, e.icon, e.disabled, e.subs, e.visible, e.help));                
                                }})) }
                            </div>
                        </div>
                    
                    </>}

                </div>
                    { info && String(info).length > 1 && <>
                <div className='bcontext-separador'/>
                <div style={{ display:"flex", flexDirection:"row", padding:"6px", textAlign:"center", 
                            alignItems:"center", justifyContent:"center", color:"rgb(37, 82, 121)"}}>
                <div>{info}</div> 
                {
                    /*
                    Verifique as classificações dos tributos, para que não ocorram problemas depois!
                    */
                }  
                </div>
                </>}
            </div>
            </> }
        </>)

    }

  export const BNum = ({reef, className, minWidth="52px", name, comBtn=!isMobile,bordaCor, titulo, height, hint, btnPlus, value=0, disabled, onKeyUp = () => {},
                        def, cor, modelo=1, max=1, min=1, setValue,onExit,onEnter ,next, width="100px", pulse=true}) => {
    //const { signIn, loading, setUser, user } = useContext(AppContext);
    // DEF = valor default
    const he = titulo ? height ? "calc("+  height+" + 20px)" : "calc(26px + 20px)" : height ? height : "26px";
    const autoIDtemp = "5645asd2a1d65w46q5e";
    const [modoFloat, setModoFloat] = useState(String(value).includes(",") || String(value).includes("."));
    const [carregando, setCarregando] = useState(false);
    // ----------------------------
    const [ tmp, setTmp ] = useState(value);   
    useEffect(() => {
        if(value != tmp){
            setTmp(value);
        }
       // toast.success(value)
     }, [value])         
    useEffect(() => {
        if(value != tmp){
            setValue(tmp);
        }
       // toast.success(value)
     }, [tmp])
     // ----------------------------

    useEffect(() => {
        if(String(value).includes(",") || String(value).includes(".")){
            if(!modoFloat){
                setModoFloat(true);
            }
        }
       // toast.success(value)
     }, [value])

    useEffect(() => {
       if(modoFloat){
        let obbj = document.getElementById(autoIDtemp);
        if(obbj){
            obbj.focus();
            obbj.setSelectionRange(obbj.value.length, obbj.value.length);
        }
       }
    }, [modoFloat])


    const currencyMask = createNumberMask({
        prefix: '',
        decimalSymbol: ',', 
        includeThousandsSeparator: true,
        thousandsSeparatorSymbol: '.',
        allowDecimal: true,
        decimalLimit: 3,
        integerLimit: 13,
        allowNegative: false,
        allowLeadingZeroes: false,
    });

    function createClass(mdl){
        let base = "";
        let classe = "";
        //---------------------------------------
        if(mdl == 1){ classe = "bedit-model-1"; }
        else if(mdl == 2){ classe = "bedit-model-2"; }
        else{ classe = "bedit-model-3"; }    
        //---------------------------------------
        return(classe + " " +base)
    }

    return(
        <BMolder modelo={modelo} className={className} width={width} minWidth={minWidth} cor={cor} bordaCor={bordaCor} bordaSize={1}
                 titulo={titulo} disabled={disabled} height={height ? height : null}>
   
                
                { modoFloat ? 
                    <MaskedInput id={autoIDtemp} name={ name ? name : null }
                        className={createClass(modelo ? modelo : 1) + (pulse ? " bmolder-input": "")}
                        style={{ backgroundColor: cor ? cor : null, textAlign:"center"}}
                        mask={currencyMask} 
                        value={ floatToReal(tmp, 3) }
                        disabled={disabled}
                        onChange={(e) => {setTmp(realToFloat(e.target.value, 0, 3)) }} 
                        onFocus={(e) => e.target.select() }
                        inputMode='numeric'    
                    /> 
                :             
                    <div className={"number-input-interno"}  >    
                     {/*  {comBtn == true && !disabled && <>     
                    
                        <button onClick={ () => { 
                                    if( safeInt(tmp, min)-1 >= min ){ 
                                        setTmp(safeInt(tmp,min)-1) 
                                    }                                
                              
                                
                            }} >{"-"}</button>
                           
                        </>} */}
                      
                        { !carregando && 
                        <input type="number" name={name ? name : null }
                            className={createClass(modelo ? modelo : 1) + " quantity " + (pulse ? "bmolder-input": "")}
                            min={min} max={max} defaultValue={def ? def : null}  
                            ref={reef} 
                            placeholder={hint ? hint : null}
                            style={{ backgroundColor: cor ? cor : null, textAlign:"center"}}
                            disabled={disabled}
                            onKeyUp={ (e) => { onKeyUp(e) }}
                            onFocus={(e) =>  (e.target.select(), onEnter ? onEnter() : null)  }
                            onBlur={ onExit ? () => { onExit() } : null  }
                            onKeyDown={ (e) => { if(e.key == ","){  setModoFloat(true); setTmp(e.target.value+",") } handleEnter(e, next)} } 
                            onChange={ (e) => { if(String(e.target.value).length <= 0 || safeInt(e.target.value) < min){setTmp(min)}else{setTmp(e.target.value)}   }} 
                            value={ ( String(tmp).includes(",") || String(tmp).includes(".") ) ? tmp : tmp ? safeInt(tmp,min) : min }
                        />
                    }
                       {/* 
                          {comBtn == true && !disabled && <>   
                            <button onClick={ () => {if(safeInt(tmp,min)+1 <= max){                           
                                    setTmp(safeInt(tmp,min)+1); setCarregando(true); delay(()=>{setCarregando(false)})  } }} >{"+"}</button>     
                            { btnPlus && btnPlus > 0 && <div style={{marginLeft:"5px", minHeight:"19px", minWidth:"19px"}}>
                                <button style={{backgroundColor:"white", minHeight:"19px", minWidth:"19px"}} onClick={ () => {if(safeInt(tmp,min)+parseInt(btnPlus) <= max){                           
                                    setTmp(safeInt(tmp,min)+parseInt(btnPlus)) } }} ><p style={{fontSize:"10px"}}>{String(btnPlus)}</p></button> 
                            </div>}    
                        </>}
                        */}
                    </div>   
                }
       </BMolder>
    )
}

export const BMolder = ({id, children, icon, modelo=1, className, width, minWidth, maxWidth, cor, titulo, bordaCor, bordaSize=2, semFoco,
                         multiline, disabled, marginTop, marginBottom, height="37px", minHeight, style={}, pulse=false }) => {
    function createFundoClass(mdl){
        let base = "";
        let classe = "";
        //---------------------------------------
        if(mdl == 1){ classe = "bedit-fundo-model-1"; }
        else if(mdl == 2){ classe = "bedit-fundo-model-2"; } // all bordas, com pequena margem
        else if(mdl == 3){ classe = "bedit-fundo-model-3"; }
        else{ classe = "bedit-fundo-model-4"; }    
        //---------------------------------------
        return(classe + " " +base)
    }

    let tmp_height = height;

    if(!height && height == null){
        tmp_height = "37px";
    }

    function ghExtr(emu){
        return(emu ? " + 5px " : " + 0px ")
    }

    let isHor = false;
    if(modelo == 4){
        isHor = true;
    }

    const he = isHor ? ("calc("+  tmp_height +ghExtr(multiline) +")") : 
    ( titulo && String(titulo).length > 0 ? "calc("+  tmp_height + ghExtr(multiline) +" + 18px)" : "calc("+  tmp_height +ghExtr(multiline) +")" );

    

    return(<>
        <div  id={id ? id : null} className={"bmolder " + createFundoClass(modelo)+ (pulse ? " bm-force-pulse " : " ")+ (className ? " "+className : " ")} tabIndex={semFoco ? "-1" : null}
                 style={{...style, width: width && width, minWidth: minWidth && minWidth, maxWidth: maxWidth && maxWidth, 
                    height: he,  minHeight: minHeight && minHeight, border: bordaCor ? String(bordaSize)+"px solid "+String(bordaCor) : null,
                    marginTop: marginTop ? marginTop : null, marginBottom: marginBottom ? marginBottom : null,
                 backgroundColor: disabled ? ('rgb(242, 242, 242)') : (cor ? cor : 'white') }}> 
                 {icon ? <>{/* rgb(242, 242, 242)   rgb(248,248,248)*/}
                <div style={{display:"flex", flexDirection:"row", height:"100%", width:"100%", paddingRight:"8px"}}>
                    <div style={{display:"flex", flexDirection:"row", height:"auto", alignItems:"center", justifyContent:"center", paddingLeft:"8px", paddingRight:"8px"}}>
                        {icon}
                    </div>
                    <div style={{display:"flex", flexDirection:"column", width:"100%", height:"100%", justifyContent:"center", marginBottom:"0px"}}>
                        { titulo && String(titulo).length > 0 && <div className="bedit-item-title">{titulo}</div>}
                        <div  style={{height:"auto",width:"100%", backgroundColor:cor ? cor : 'white', overflow:"hidden"}}>          
                            {children}
                        </div>
                    </div>
                </div>
                 </>:
                 <>
             
                    { titulo && String(titulo).length > 0 && <div className="bedit-item-title" 
                            style={{marginTop: modelo == 4 ? "2px" : null, marginLeft: modelo == 4 ? "2px" : null }} >{titulo}</div>}
                    <div  style={{height:"100%",width:"100%", display:"flex", backgroundColor:cor ? cor : 'white', overflow:"hidden"}}>          
                        {children}
                    </div>
         
                 </>}
             
        </div>
    </>)
}

export const BPassw =({id,name,next,titulo,modelo,reef,className,height,minHeight,width,maxWidth,minWidth,cor,is,limite,value,setValue,disabled,
    tipo_numero,tipo_alfa,tipo_valor,tipo_pass,tipo_doc,multiline,mask, upper, pulse=true, autoComplete,
    btnExtraClick, btnExtraHint="",btnExtraIcon=<AiFillSetting size={17}/>,
    buscar,bold,hint,onEnter,onKeyEnter,onKeyUp,onExit,align,btn_busca_tx,btn_busca_auto}) => {
        const { isMobile } = useContext(AppContext);
    const [password, setPassword] = useState('');
    const [passwordCrip, setPasswordCrip] = useState('');

    useEffect(() => {
      setValue(String(password));
     }, [password])

    function inserirString(string, inserir, posicao) {
       // toast.warn(posicao)
        return string.substring(0, posicao) + inserir + string.substring(posicao);
      }

    function keyup(e){
        if(e.ctrlKey == false ){
        let p = password;
        let pos = e.target.selectionStart;
        let kperm = ['W','A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','X','Z','Ç','Y','!','@','#','$','%','&','¨','*','(',')',
                     '{','}','[',']','_','-','-','+',':',';','.',',','¬','§','?','|','^','~','=','0','1','2','3','4','5','6','7','8','9','ª','º','¢']
        //toast.success(String(pos)+'|'+e.key);
        if(kperm.includes(String(e.key).toUpperCase())){
            p = inserirString(p, String(e.key), pos)
           // p += String(e.key);
            setPassword(p)
            
        }else{
            if(String(e.key) == "Backspace"){
               // toast.error(e.key);
                if(p.length > 0){
                    p = p.substring(0, p.length - 1);
                    setPassword(p);

                }
              
            }
        }

        let t = "";
        //setPassword(v);
        if(p.length > 0){
            for (let i = 0; i < p.length; i++) {
                t += "#"
            }
        }
        setPasswordCrip(t);
        }
    };

    function createClass(mdl){
        let base = "";
        let classe = "";
        //---------------------------------------
        if(mdl == 1){ classe = "bedit-model-1"; }
        else if(mdl == 2){ classe = "bedit-model-2"; }
        else{ classe = "bedit-model-3"; }    
        //---------------------------------------
        return(classe + " " +base)
    }
    
    return (
        <BMolder id={id ? id : null} modelo={modelo ? modelo : 1} className={className} 
        height={height ? height : null} minHeight={minHeight ? minHeight : null} maxWidth={maxWidth} 
        width={width} minWidth={minWidth} cor={cor} titulo={ 
           titulo && <>
               <div style={{display:"flex", justifyContent:"space-between", overflow:"hidden", alignItems:"center"}}>
                   <p>{titulo}</p>
                   { is ? ((is == "OK" || is == "ALERTA") ? "" : <p style={{color:"red"}}>{String(is)}</p>) : null}
                   { limite > 0 && <>
                   <div className='bcombo-btn-extra'  
                       style={{marginTop:"2px", marginRight:"5px", cursor:"default"}} >     
                       { parseInt(retornaApenasNumeros(width)) >= 100 && <>                
                           <p>{String(value).length}/{limite}</p>   
                       </>}               
                   </div></>}
                   { btnExtraClick && <>
                   <div className='bcombo-btn-extra'  
                           style={{marginTop:"2px", marginRight:"5px"}} >
                       <Tooltip title={btnExtraHint} >
                          
                           <div onClick={()=> {btnExtraClick();}}>
                           {btnExtraIcon}
                           </div>
                           
                           
                       </Tooltip>
                   </div></>}
               </div>
           </>
       } 
        disabled={disabled}
        bordaCor={is ? (is == "OK" ? "rgb(4, 228, 0)" : is == "ALERTA" ? "rgb(253, 255, 15)" : "red") : null}
   
       >
        {isMobile ? <>
       
           <input name={name ? name : null} 
                  className={createClass(modelo ? modelo : 1)+(pulse ? " bmolder-input ": "")}
            type='password'
            value={password}
            autoComplete="off"

            readOnly
            onClick={(e) => e.target.removeAttribute('readOnly')}
            onFocus={(e) => e.target.removeAttribute('readOnly')}
            onBlur={(e) => e.target.setAttribute('readOnly', true)}

            onChange={ (e) => { setPassword(e.target.value) }}
            onKeyUp={ onKeyEnter ? 
                        (e) => (e.key == "Enter" || e.key == "Next" || e.key == "Go" || e.key == "Okay" || e.key == "Ok") && ( onKeyEnter(e) ) 
                        :   
                        null 
                    }    
            style={{ height:"32px", minHeight:"32px", maxHeight:"32px", backgroundColor: cor ? cor : null, textAlign: align ? align : null, 
                fontWeight: bold ? 700 : null,  textTransform: (upper || tipo_alfa) ? "uppercase" : null }}
            disabled={disabled}
                
          />
        </> : <>      
          <input name={name ? name : null} className={createClass(modelo ? modelo : 1)+(pulse ? " bmolder-input ": "")}
            
            value={passwordCrip}
            onKeyDown={ e => keyup(e) }

            maxlength={limite && limite > 0 ? String(limite) : null }
            onKeyUp={ onKeyEnter ? 
                        (e) => (e.key == "Enter" || e.key == "Next" || e.key == "Go" || e.key == "Okay" || e.key == "Ok") && ( onKeyEnter(e) ) 
                        :   
                        null 
                    }    
            style={{ backgroundColor: cor ? cor : null, textAlign: align ? align : null, 
                fontWeight: bold ? 700 : null,  textTransform: (upper || tipo_alfa) ? "uppercase" : null }}
            disabled={disabled}
                
          />
          </>}
      </BMolder>
    );
  };

export const BEdit = ({id,name,next,titulo,modelo,reef,className,height,minHeight,width,maxWidth,minWidth,cor,is,limite,value,setValue,disabled,
                       tipo_numero,tipo_alfa,tipo_valor,tipo_pass,tipo_doc,multiline,mask, upper, pulse=true, autoComplete, semTeclado=false, help="",
                       btnExtraClick, btnExtraHint="",btnExtraIcon=<AiFillSetting size={17}/>, placeHolderColor=false, delayChange, detectaKeyEnter=true,
                       buscar,bold, fontCor="black",hint,onEnter,onKeyEnter,onKeyUp,onExit,align,btn_busca_tx,btn_busca_auto}) => {
    //const { signIn, loading, setUser, user } = useContext(AppContext);


    // -----------------------------
    const [timer, setTimer] = useState(null);
  
    useEffect(() => {
        if(delayChange){
            function handleTimeout(){
                delayChange(value)
                //toast.success(value)
            }

            if (timer) { clearTimeout(timer) }      

            if (value) { setTimer(setTimeout(handleTimeout, 400)) }

            return () => clearTimeout(timer);
        }     
    }, [value]);
  

    /*
        
    const TimerActionComponent = ({isKeyPressed, setIsKeyPressed, action=()=>{} }) => {
        useEffect(() => {
   
            const handleKey = (event) => {      
                    clearTimeout(timeoutId);
                    if(!timeoutId){            
                            setIsKeyPressed(true);
                            timeoutId = setTimeout(() => {   
                                clearTimeout(timeoutId);
                                setIsKeyPressed(false);
                                action();
                            }, 1000);    
                            
                    }
            };
                
            window.addEventListener('keyup', handleKey);  
            return () => { 
                window.removeEventListener('keyup', handleKey);
            };
        }, []);
  
        return(<></>);
    };
*/

    /// --------------------------



    const [ tmp, setTmp ] = useState(value);
     
    useEffect(() => {
        if(value != tmp){
            setTmp(value);
        }
       // toast.success(value) autoComplete
     }, [value])

         
    useEffect(() => {
        if(value != tmp){
            setValue(tmp);
        }
       // toast.success(value)
     }, [tmp])


    function createClass(mdl){
            let base = "";
            let classe = "";
            //---------------------------------------
            if(mdl == 1){ classe = "bedit bedit-model-1"; }
            else if(mdl == 2){ classe = "bedit bedit-model-2"; }
            else{ classe = "bedit bedit-model-3"; }    
            //---------------------------------------

            if(placeHolderColor){
                classe += " bedit-color-place-holder "
            }

            return(classe + " " +base)
        }

    function alfaNum(e=""){
        let res = safeStr(e).toUpperCase();
        res = res.replaceAll("  ","").replaceAll(" ","").replaceAll('"','').replaceAll("'","").replaceAll("¨","").replaceAll("'","").replaceAll("!","");
        res = res.replaceAll("@","").replaceAll("#","").replaceAll("$","").replaceAll("%","").replaceAll("&","").replaceAll("*","").replaceAll("(","");
        res = res.replaceAll(")","").replaceAll("-","").replaceAll("_","").replaceAll("+","").replaceAll("=","").replaceAll("§","").replaceAll("¬","");
        res = res.replaceAll("\\","").replaceAll("|","").replaceAll("<","").replaceAll(">","").replaceAll(",","").replaceAll(".","").replaceAll(":","");
        res = res.replaceAll(";","").replaceAll("?","").replaceAll("ª","").replaceAll("º","").replaceAll("°","").replaceAll("/","").replaceAll(" ","");
        return res;
    }
        
    return(<>

            <BMolder id={id ? id : null} modelo={modelo ? modelo : 1} className={className} 
                     height={height ? height : null} minHeight={minHeight ? minHeight : null} maxWidth={maxWidth} 
                     width={width} minWidth={minWidth} cor={cor} titulo={ 
                        titulo && <>
                            <div style={{display:"flex", justifyContent:"space-between", overflow:"hidden", alignItems:"center"}}>
                                <p>{titulo}</p>
                                { is ? ((is == "OK" || is == "ALERTA") ? "" : <p style={{color:"red"}}>{String(is)}</p>) : null}
                                { limite > 0 && <>
                                <div className='bcombo-btn-extra'  
                                    style={{marginTop:"2px", marginRight:"5px", cursor:"default"}} >     
                                    { parseInt(retornaApenasNumeros(width)) >= 100 && <>                
                                        <p>{String(value).length}/{limite}</p>   
                                    </>}               
                                </div></>}
                                { btnExtraClick && <>
                                <div className='bcombo-btn-extra'  
                                        style={{marginTop:"2px", marginRight:"5px"}} >
                                    <Tooltip title={btnExtraHint} >
                                       
                                        <div onClick={()=> {btnExtraClick();}}>
                                        {btnExtraIcon}
                                        </div>
                                        
                                        
                                    </Tooltip>
                                </div></>}
                                {String(help).length > 0 && <div><Tooltip title={String(help)} 
                                        zIndex={999999}><FaQuestionCircle size={14} color={"rgb(69, 106, 121)"} 
                                        style={{marginLeft:"5px"}}/></Tooltip></div> }  
                            </div>
    
                        </>
                    } 
                     disabled={disabled}
                     bordaCor={is ? (is == "OK" ? "rgb(4, 228, 0)" : is == "ALERTA" ? "rgb(253, 255, 15)" : "red") : null}
                
                    >
                   { multiline == true ?
                    <textarea name={name ? name : null} className={createClass(modelo ? modelo : 1)+(pulse ? " bmolder-input ": "")}
                        type={tipo_numero || tipo_valor ? "number" : "text"} 
                        ref={reef} 
                        style={{ backgroundColor: cor ? cor : null, textAlign: align ? align : null, 
                            fontWeight: bold ? 700 : null, minHeight: minHeight ? minHeight : null,
                            textTransform: (upper || tipo_alfa) ? "uppercase" : null }}
                        disabled={disabled}
                        placeholder={hint && hint}
                        onFocus={(e) =>  (e.target.select(), onEnter ? onEnter() : null)  }
                        maxlength={limite && limite > 0 ? String(limite) : null }
                        onKeyDown={ buscar && value.length > 0 ? null : (e) => handleEnter(e, next)}
                        onKeyUp={ 
                            buscar ? 
                                (e) => e.key == "Enter" || e.key == "Next" || e.key == "Go" || e.key == "Okay" || e.key == "Ok" ? 
                                        (buscar(value), handleEnter(e, next) ) 
                                    : 
                                       onKeyUp ? onKeyUp(e.key.toLowerCase()) : null  
                            :   
                                onKeyUp ? (e) => onKeyUp(e.key.toLowerCase()) : null 
                            } 
                    onChange={(e) => setValue && setValue( tipo_valor ? (e.target.value) : e.target.value)} 
                    value={value}/>
                    : mask ? 
                    <MaskedInput name={ name ? name : null }
                    mask={mask} 
                    maxlength={limite && limite > 0 ? String(limite) : null }
                    className={createClass(modelo ? modelo : 1)+(pulse ? " bmolder-input ": "")}
                        type={tipo_numero || tipo_valor ? "number" : tipo_doc ? "tel" : "text"} 
                        ref={reef} 
                        style={{ backgroundColor: cor ? cor : null, textAlign: align ? align : null, color: fontCor ? fontCor : "black",
                            fontWeight: bold ? 700 : null,  textTransform: (upper || tipo_alfa) ? "uppercase" : null }}
                        disabled={disabled}
                        placeholder={hint && hint}
                        onFocus={(e) =>  (e.target.select(), onEnter ? onEnter() : null)  }
                        onBlur={ onExit ? () => { onExit() } : null } 
                        onKeyDown={ buscar ? null : (e) => handleEnter(e, next)}
                        onKeyUp={ 
                            
                            buscar ?        
                            (e) => e.key == "Enter" || e.key == "Next" || e.key == "Go" || e.key == "Okay" || e.key == "Ok"? 
                                    (buscar(value), handleEnter(e, next) ) 
                                : 
                                    onKeyUp ? onKeyUp(e.key.toLowerCase()) : null  
                        :   
                            onKeyUp ? (e) => onKeyUp(e.key.toLowerCase()) : null 
                            } 
                    onChange={(e) => setValue && setValue( tipo_valor ? (e.target.value) : e.target.value)} 
                    value={value}

                    //inputMode='numeric'       
                    />               
                    :
                    <input inputmode={semTeclado && 'none'} name={name ? name : null} className={createClass(modelo ? modelo : 1)+(pulse ? " bmolder-input ": "")}
                        type={tipo_numero || tipo_valor ? "text" : buscar ? "search" : tipo_pass ? "password": "text" } 
        
                        ref={reef} 
                        autoComplete={tipo_pass ? autoComplete: "off" }
                        maxlength={limite && limite > 0 ? String(limite) : null }
                        style={{              
                            backgroundColor: cor ? cor : null, textAlign: align ? align : null, 
                            color: fontCor ? fontCor : "black",
                            fontWeight: bold ? 700 : null,  
                            textTransform: (upper || tipo_alfa) ? "uppercase" : null,                        
                        }}
                        disabled={disabled}
                        up
                 
                 
                        placeholder={hint && hint}
                        onFocus={(e) =>  (e.target.select(), onEnter ? onEnter() : null)  }
                        onBlur={ onExit ? () => { onExit() } : null } 
                        onKeyDown={ 
                       

                            (buscar && detectaKeyEnter) ? null : (event) => { 
                                handleEnter(event, next); 
                                // toast.success(event.key)
                                if(tipo_alfa && (event.key == ' ' || event.key == ' ' || event.key == '"' || event.key == "'" 
                                || event.key == '¨'|| event.key == '&'|| event.key == '%'|| event.key == '$'|| event.key == '!'|| event.key == '*'
                                || event.key == '_'|| event.key == '-'|| event.key == '='|| event.key == '+'|| event.key == '-'|| event.key == '/'
                                || event.key == '\\'|| event.key == ','|| event.key == '.'|| event.key == ':'|| event.key == ';'|| event.key == '?'
                                || event.key == 'ª'|| event.key == 'º'|| event.key == '<'|| event.key == '>'|| event.key == '('|| event.key == ')'
                                || event.key == '['|| event.key == ']'|| event.key == '{'|| event.key == '}'|| event.key == '|'|| event.key == '.')){
                                    event.preventDefault()
                                } 
                                if( tipo_numero && event.key < 0 && event.key > 9){
                                    event.preventDefault()
                                }
                                
                            }
                      
                        
                        }

                        onKeyPress={ 
                
                            (buscar && detectaKeyEnter) ?        
                                (e) => e.key == "Enter" || e.key == "Next" || e.key == "Go" || e.key == "Okay" || e.key == "Ok"? 
                                    (  buscar(value), handleEnter(e, next) ) 
                                    : 
                                    onKeyUp ? onKeyUp(e.key.toLowerCase()) : null  
                            : (onKeyEnter && detectaKeyEnter) ? 
                                (e) => (e.key == "Enter" || e.key == "Next" || e.key == "Go" || e.key == "Okay" || e.key == "Ok") && 
                                    ( onKeyEnter(e) ) 
                            :   
                            onKeyUp ? (e) => onKeyUp(e.key.toLowerCase()) : null 
                            }    
                    value={tmp}       
                    onChange={(e) => setValue && ( tipo_numero ? 
                                        setValue( retornaApenasNumeros(String(e.target.value)))
                                        : 
                                        upper ? setValue(e.target.value.toUpperCase() ) 
                                                : 
                                                tipo_alfa ? setValue(alfaNum(e.target.value) ) 
                                                            : 
                                                            setTmp(e.target.value) 
                                    ) } 
                    />


                    

                    }
                    
                    { btn_busca_auto && <>
                        <Tooltip title={"Busca automática ativada"}>
                        <div style={{marginRight:"0px", marginTop:"0px", padding:"4px", 
                                     width:"auto", height:"100%", display:"flex" ,alignItems:"center"  }}>
                        <div style={{marginRight:"0px", marginTop:"0px", padding:"4px", 
                                     width:"auto", paddingLeft:"7px", paddingRight:"6px",height:"100%", display:"flex" ,
                                     alignItems:"center", justifyContent:"space-around",
                                     backgroundColor:"rgb(231, 231, 231)", borderRadius: "10px"  }}>
                            <p style={{fontSize:"11px", color:"rgb(150, 150, 150)",  }} >{ 
                                     btn_busca_tx ? ( btn_busca_tx == "-" ? "" : btn_busca_tx ) : "auto" } </p>
                            <VscSearch size={14} style={{ minWidth:"14px" }} color={"rgb(150, 150, 150)"} onClick={ (e) => {  
                            if(buscar ) { buscar(value); handleEnter(e, next); }
                            } } />
                        </div></div></Tooltip>
                    </>}
                    { buscar && !btn_busca_auto && <div style={{height:"100%", display:"flex", alignItems:"center", paddingTop:"2px"}}>
                        <VscSearch size={18} style={{marginRight:"8px", cursor:"pointer", minWidth:"18px"}} onClick={ (e) => {  
                        if(buscar ) { buscar(value); handleEnter(e, next); }
                        } } />
                    </div>}
                  
            </BMolder>
    </>)
}

const dateFormat = 'DD/MM/YYYY';


export const BAppContent = ({children,titulo,sub,submenu,voltar, sem_margem=false, onMenuClick = () => { } }) => {
    //const { signIn, loading, setUser, user } = useContext(AppContext);
    
    const { signIn, loading, setUser, user, menuAberto, setMenuAberto } = useContext(AppContext);

    useEffect(() => {
        ReactGA.send({hitType: "pageview", page: window.location.pathname, title: window.location.title, });
        
       // toast.success(window.location.pathname)
     }, [])

    return(<>   
        <div className={'app-box'}>
            <div className={`app-content ${ sem_margem ? `app-padding-sem` : `app-padding-com` }`}>
                { children }
            </div>
            </div>
        </>
    )
}

export const BSpin = ({children, display=false, carregando=true, texto="Carregando...", sub="", txBtn, corBtn, onClick}) => {
    //const { signIn, loading, setUser, user } = useContext(AppContext);
    return(<>
            {display ? <>
                <div className={'bspin-content'} >
                { children }
                <div className={'bspin-box'} >           
                    <div className={'bspin-center'} >
                        { carregando && <>
                            <LoadingOutlined style={{ fontSize: 38}} spin />
                            <p className="bspin-texto">{texto}</p> 
                            <p>{sub}</p> 
                        </> }
                     
                        
                    </div>           
                </div>
                </div>
            </>:<>
            { children }</>}         
        </>
    )
}


export const BSpinIntern = ({children, item_pai, display=false, carregando=true, texto="Carregando...", sub="",opacidade, txBtn, corBtn, onClick}) => {
    //const { signIn, loading, setUser, user } = useContext(AppContext);
    //const  ta = {offsetTop: "10px", offsetLeft: "10px", offsetLeft: "10px", offsetHeight: "10px"}
    const [ ta, setTa ] = useState({offsetTop: "10px", offsetLeft: "10px", offsetLeft: "10px", offsetHeight: "10px"});

    useEffect(()=> {   
        if(item_pai){
            try{
                if(document.getElementById(item_pai) != null){
                    setTa(document.getElementById(item_pai));
                }else{
                    delay(()=> {                    
                        if(document.getElementById(item_pai) != null){
                            setTa(document.getElementById(item_pai));
                            }
                    }, 1500);
                }
                //  delay(()=> { ta = document.getElementById(item_pai);  }, 1000);
            
            }catch{
               // alert(item_pai)
            }
        }

    },[]);
  

    return(<>
            {display ? <>
                
                <div className={'bspin-intern-box'} style={{top:ta.offsetTop, left:ta.offsetLeft, height: ta.offsetHeight, minHeight: ta.offsetHeight, 
                                                            width: ta.offsetWidth, minWidth:ta.offsetWidth, opacity:opacidade ? opacidade : null}} >           
                    <div className={'bspin-intern-center'} style={{height: ta.offsetHeight, minHeight: ta.offsetHeight}}>
                        { carregando && <>
                            <LoadingOutlined style={{ fontSize: parseInt(ta.offsetHeight) <= 80 ? 20 : 38}} spin />
                        </> }
                        { texto.length > 0 && <>
                            <p className="bspin-intern-texto" style={{ fontSize:  parseInt(ta.offsetHeight) <= 80 ? 14 : 20 }}>{texto}</p> 
                            <p>{sub}</p> 
                        </> }
                        
                     
                     
                        
                    </div>           
                </div>
                { children }
            </>:<>
            { children }</>}         
        </>
    )
}

export const BSpinDiv = ({className, style, id, children, display=false, carregando=true, texto="Carregando...", sub,opacidade, txBtn, corBtn="black", onClick}) => {
    //const { signIn, loading, setUser, user } = useContext(AppContext);
    //const  ta = {offsetTop: "10px", offsetLeft: "10px", offsetLeft: "10px", offsetHeight: "10px"}
   
    return(<>
        <div className={ (className ? (className+ " ") : "") + "bspin-div-div"} style={style} id={id} autocomplete={"off"}>
            {display ? <>
                
                <div className={'bspin-div-box'} style={{opacity:opacidade ? opacidade : null}} >           
                    <div className={'bspin-div-center'}>
                        <div className={'bspin-div-tx'}>
                            { carregando && <>
                            
                            
                            
                            
                       
                                
                              {/*
                                <Icon arq={icon_load} size={"60px"} marginLeft={"0px"} marginRight={"0px"} modoGif={true} />
                               */}  
                           
                              { <div style={{height:"40px", width:"40px"}}>
                                    <LoadingOutlined style={{ fontSize: 24, color:"black", marginRight:"8px"}}  spin />
                                </div>
                                // 
                              }  
                            </> }
                            { texto.length > 0 && <><div className={'bspin-div-info'}>
                                <p className="bspin-div-texto" style={{ fontSize: 14 }}>{texto}</p> 
                                { sub && <><div>{sub}</div>  </> }
                                </div> 
                            </> }
                        </div>

                        <div>
                            {txBtn && onClick && <BButton cor={corBtn} texto={txBtn} onClick={()=>{onClick()}}/>}      
                        </div>    
                     
                        
                    </div>    
                    
                </div>
                { children }
            </>:<>
            { children }</>}      
        </div>   
        </>
    )
}

export const BDate = ({modelo, tipo, className, value="", setValue = ()=> {},
                       width, minWidth, cor, titulo, disabled, debug=false }) => {
    //const { signIn, loading, setUser, user } = useContext(AppContext);
    const [ d_formatBR, setD_formatBR ] = useState('DD/MM/YYYY');
    const d_format = 'YYYY-MM-DD';

    const [interno_data, setInterno_Data] = useState('');

    useEffect(() => {
        if(tipo)
        {
            if(tipo == 'date'){ // 
                setD_formatBR('DD/MM/YYYY');
              }else if(tipo == 'month'){ // 
                setD_formatBR('MM/YYYY');
              }else if(tipo == 'year'){ //
                setD_formatBR('YYYY');
              }else{ // 
                setD_formatBR('DD/MM/YYYY');
              }
        }

      }, []);

    function createClass(mdl){
            let base = "";
            let classe = "";
            //---------------------------------------
            if(mdl == 1){ classe = "bedit-model-1"; }
            else if(mdl == 2){ classe = "bedit-model-2"; }
            else{ classe = "bedit-model-3"; }    
            //---------------------------------------
            return(classe + " " +base)
        }

      
    
        useEffect(() => {
   
            //let ttt = "2020-05-02"
            //let ttt = "01/05/2020"
            let isBR = false;
            if(String(value).includes('/')){ isBR = true }

            

            if(String(dayjs(interno_data, d_formatBR).format(d_format)) != String(value)){
            
                if(String(value).length > 4 && String(value) != "invalid date"){
                    let tmp = ""
                    if(isBR){
                        tmp = (dayjs(String(value).toLocaleString(), d_formatBR))
                    }else{
                        tmp = (dayjs(String(value).toLocaleString(), d_format))
                    }  
                    setInterno_Data(tmp);
                    if(String(value) != String(tmp.format(d_format))){
                        setValue(String(tmp.format(d_format)));
                    }               
                }else{           
   
                    let tmp = dayjs(new Date());
                    setInterno_Data(tmp);
                    setValue(String(tmp.format(d_format)) );
                }
            }
        }, [value]);  

    return(<>    
       { debug && <>
        <p>Data :: {String(value)}</p>
        <p>Interno :: {String(interno_data)}</p>   
        </>}
        <BMolder modelo={modelo ? modelo : 1} className={className} width={width ? width : "250px"} 
                 minWidth={minWidth ? minWidth : null} cor={cor} titulo={titulo} disabled={disabled}>
  
        
        <DatePicker className={createClass(modelo ? modelo : 1)} size="small" picker={tipo ? tipo : "date"}
                    value={interno_data} disabled={disabled} onFocus={(e) => e.target.select() }
                    format={d_formatBR} style={{width: "100%" }} width={"100%"} 
                    onChange={(e) => { 
                        setInterno_Data(e); 
                        setValue(String(dayjs(e, d_formatBR).format(d_format)) );
                    }} 
        />
 
            {/* 
                onChange={(date, dateString) => {props.setValue(String(safeStr(dateString).split("T",1)).replace("[").replace("'").replace("]").replace("'"));} }
                value={ props.value === moment ? props.value.format(dformat) : moment(props.value, dformat) }
                
                />    
            */}
        </BMolder></>
    )
}

export const BDate2 = ({modelo, tipo, className, value="", setValue = ()=> {},
                       width, minWidth, cor, titulo, disabled, debug=false }) => {
    //const { signIn, loading, setUser, user } = useContext(AppContext);
    const [ d_formatBR, setD_formatBR ] = useState('DD/MM/YYYY');
    const d_format = 'YYYY-MM-DD';

    const [interno_data, setInterno_Data] = useState('');

    useEffect(() => {
        if(tipo)
        {
            if(tipo == 'date'){ // 
                setD_formatBR('DD/MM/YYYY');
              }else if(tipo == 'month'){ // 
                setD_formatBR('MM/YYYY');
              }else if(tipo == 'year'){ //
                setD_formatBR('YYYY');
              }else{ // 
                setD_formatBR('DD/MM/YYYY');
              }
        }

      }, []);

    function createClass(mdl){
            let base = "";
            let classe = "";
            //---------------------------------------
            if(mdl == 1){ classe = "bedit-model-1"; }
            else if(mdl == 2){ classe = "bedit-model-2"; }
            else{ classe = "bedit-model-3"; }    
            //---------------------------------------
            return(classe + " " +base)
        }

      
    
        useEffect(() => {

            //let ttt = "2020-05-02"
            //let ttt = "01/05/2020"
            let isBR = false;
            if(String(value).includes('/')){ isBR = true }

           // if(isBR){
               // alert(moment(String(value).toLocaleString(), d_formatBR))
            //}else{
               // alert(moment(String(value).toLocaleString(), d_format))
           //}
            
           // alert(moment(String(value).toLocaleString(), d_format))
         
            if(String(dayjs(interno_data, d_formatBR).format(d_format)) != String(value)){
            
                if(String(value).length > 4 && String(value) != "invalid date"){
                    let tmp = ""
                    if(isBR){
                        tmp = (dayjs(String(value).toLocaleString(), d_formatBR))
                    }else{
                        tmp = (dayjs(String(value).toLocaleString(), d_format))
                    }  
                    setInterno_Data(tmp);
                    if(String(value) != String(tmp.format(d_format))){
                        setValue(String(tmp.format(d_format)));
                    }               
                }else{           

                    let tmp = dayjs(new Date());
                    setInterno_Data(tmp);
                    setValue(String(tmp.format(d_format)) );
                }
            }
        }, [value]);  

    return(<>    
       { debug && <>
        <p>Data :: {String(value)}</p>
        <p>Interno :: {String(interno_data)}</p>   
        </>}
        <BMolder modelo={modelo ? modelo : 1} className={className} width={width ? width : "250px"} 
                 minWidth={minWidth ? minWidth : null} cor={cor} titulo={titulo} disabled={disabled}>
  
        
        <DatePicker className={createClass(modelo ? modelo : 1)} 
                    value={interno_data} disabled={disabled} onFocus={(e) => e.target.select() }
                    format={d_formatBR} style={{width: "100%" }} width={"100%"} 
                    onChange={(e) => { 
                        setInterno_Data(e); 
                        setValue(String(dayjs(e, d_formatBR).format(d_format)) );
                    }} 
        />
 
            {/* 
                onChange={(date, dateString) => {props.setValue(String(safeStr(dateString).split("T",1)).replace("[").replace("'").replace("]").replace("'"));} }
                value={ props.value === moment ? props.value.format(dformat) : moment(props.value, dformat) }
                
                />    
            */}
        </BMolder></>
    )
}


export const BDateRange = ({data_a="", data_b="", setData_a = ()=> {}, setData_b = ()=> {}, a_dias=0, b_dias=0, width }) => {

    const d_formatBR = 'DD/MM/YYYY';
    const d_format = 'YYYY-MM-DD';
    const { RangePicker } = DatePicker;

    const [interno_data_a, setInterno_Data_a] = useState('');
    const [interno_data_b, setInterno_Data_b] = useState('');

    

    useEffect(() => {
        let isBR = false;
        if(String(data_a).includes('/')){ isBR = true }

        if(String(dayjs(interno_data_a, d_formatBR).format(d_format)) != String(data_a)){
            if(String(data_a).length > 4 && String(data_a) != "invalid date"){
                    let tmp = ""
                    if(isBR){
                        tmp = (dayjs(String(data_a).toLocaleString(), d_formatBR))
                    }else{
                        tmp = (dayjs(String(data_a).toLocaleString(), d_format))
                    }  
                    setInterno_Data_a(tmp);
                    if(String(data_a) != String(tmp.format(d_format))){
                        setData_a(String(tmp.format(d_format)));
                    }               
            }else{    
                let tmp = dayjs(new Date());
                if(a_dias < 0){
                    tmp = tmp.subtract((a_dias *= -1), 'days')
                }
                setInterno_Data_a(tmp);
                setData_a(String(tmp.format(d_format)) );
              
                /*             
      
                if(a_dias > 0){
                    tmp.add(a_dias-1, 'days')
                }
                if(a_dias < 0){
                    tmp.subtract((a_dias *= -1), 'days')
                }
                setInterno_Data_a(tmp);  
    
               */
            }
        }
     
    }, [data_a]);  
    
    useEffect(() => {
        let isBR = false;
        if(String(data_b).includes('/')){ isBR = true }

         if(String(dayjs(interno_data_b, d_formatBR).format(d_format)) != String(data_b)){
             if(String(data_b).length > 4 && String(data_b) != "invalid date"){
                let tmp = ""
                if(isBR){
                    tmp = (dayjs(String(data_a).toLocaleString(), d_formatBR))
                }else{
                    tmp = (dayjs(String(data_a).toLocaleString(), d_format))
                }  
                setInterno_Data_b(tmp);
                if(String(data_a) != String(tmp.format(d_format))){
                    setData_b(String(tmp.format(d_format)));
                }               
             }else{           
                let tmp = dayjs(new Date());
                setInterno_Data_b(tmp);
                setData_b(String(tmp.format(d_format)) );          
                /*
                if(b_dias > 0){
                    tmp.add(b_dias-1, 'days')
                }
                if(b_dias < 0){
                    tmp.subtract( (b_dias *= -1) -1, 'days')
                }
                */
             }
         }
     }, [data_b]); 
                           
    return(<>
        {/*
        <p>Data A :: {String(data_a)}</p>
        <p>Data B :: {String(data_b)}</p>
        <p>Interno A :: {String(interno_data_a)}</p>
        <p>Interno B :: {String(interno_data_b)}</p>
        */}
        <RangePicker  value={[interno_data_a, interno_data_b]} 
                      format={d_formatBR} style={{width: width ? width : "230px"}} 
                      onChange={(e) => { 
                        setInterno_Data_a(e[0]); 
                        setInterno_Data_b(e[1]);   
                        setData_a(String(dayjs(e[0], d_formatBR).format(d_format)) );
                        setData_b(String(dayjs(e[1], d_formatBR).format(d_format)) ); 
                    }} 
        />
    </>)
}


const MonetaryInput = ({ value, onChange, className, name, style, disabled,onExit,onKeyDown,onKeyUp,onBlur,onFocus }) => {
    const [amount, setAmount] = useState( value && safeStr(value).length > 0 && value > 0 ? floatToReal(value) : "");

    useEffect(() => {  
        //toast.info(value)
        let ttp = floatToReal(value);
        let ttp2 = amount;
        if(ttp2 == "" || ttp2 == "0"){
            ttp2 = "0,00"
        }
        if(value > 0){
            if(ttp != ttp2){  
          
               setAmount(floatToReal(value))    
            }            
        }
        else{
            setAmount("")    
        }

      }, [value]);
  
    const handleInputChange = (event) => {
      const inputValue = (event.target.value);
  
      // Remove caracteres não numéricos
      const numericValue = inputValue.replace(/[^0-9]/g, '');
  
      // Obtém o valor dos centavos
      let cents = numericValue.slice(-2);
        if(cents.length == 1){
            cents = "0"+cents;
        }
  
      // Obtém o valor dos reais
      let reais = numericValue.slice(0, -2);
      if(reais.length >= 2 && reais[0] == "0"){
        reais = reais.substring(1);
      }

      // Formata o valor com separador de milhares
      const formattedValue = reais.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  
      // Atualiza o estado do campo de input
      let tmp = formattedValue + ',' + cents;
 
      setAmount(tmp);

        onChange(realToFloat(tmp));
    };

    return (
      <input
        className={className}
        name={name}
        type="text"
        value={amount}
        onChange={handleInputChange}
        placeholder="0,00"
        inputMode='numeric'
        rightToLeft={true}

        style={style}
        disabled={disabled} 
    
        onKeyDown={ e => onKeyDown ? onKeyDown(e) : null}
        onKeyUp={ e => onKeyUp ? onKeyUp(e) : null}
        onBlur={ e => onBlur ? onBlur(e) : null} 
        onFocus={ e => onFocus ? onFocus(e) : null}
        
      />
    );
  };
  
  export default MonetaryInput;

export const BValor = ({titulo="", className, minWidth, name, id, next, value, setValue, fontSize, cor,corFonte, modelo=1, bordaCor, disabled=false, width, txPos="end",
                        height, autoP=-1, porcento, onExit, onEnter, onKeyUp = ()=>{}, pulse=true}) => {
    /* Obs
       Entrada de valor (VALUE) é FLOAT, e a saída (setValue) por padrão tbm é FLOAT.
    */

       const idd = (id && String(id).length > 0) ? id : "BValor"+randomNumStr(999)+randomNumStr(99)+randomNumStr(9)+String(titulo.length)+randomNumStr(99)

        function createClass(mdl){
            let base = "";
            let classe = "";
            //---------------------------------------
            if(mdl == 1){ classe = "bedit-model-1"; }
            else if(mdl == 2){ classe = "bedit-model-2"; }
            else{ classe = "bedit-model-3"; }    
            //---------------------------------------
            return(classe + " " +base)
        }

        const currencyMask2 = createNumberMask({
            prefix: '',
            decimalSymbol: ',', 
            includeThousandsSeparator: false,
            thousandsSeparatorSymbol: '.',
            allowDecimal: true,
            decimalLimit: 3,
            integerLimit: 3,
            allowNegative: false,
            allowLeadingZeroes: false,
            default: 0,
        });


       function pressP(e){
        if(autoP >= 0 && e.keyCode === 32){ // espaço = 32

            setValue(autoP);
        }
       }

    useEffect(() => {
        if(isFocusedComponentPorID(idd)){
            if(String(value) == "0" || String(value) == "0,00" || 
                String(value) == "0,000" || String(value) == "0.00"|| String(value) == "0.000"){       
                selectComponentPorID(idd)
            }
        }
    }, [value]);


    return(
        <BMolder modelo={modelo} className={className} width={width} minWidth={minWidth} cor={cor} bordaCor={bordaCor} bordaSize={1}
                 titulo={titulo} disabled={disabled} height={height ? height : null}>
                    { !porcento && <>
                <MonetaryInput 
                    className={createClass(modelo ? modelo : 1)+(pulse ? " bmolder-input ": "")}
                    value={ value }
                    onChange={ (e) => { setValue(e) } } 
                    name={name ? name : null }
                    id={idd}
                    style={{ backgroundColor: cor ? cor : null, textAlign:txPos, fontSize: fontSize ? fontSize : null, color: corFonte ? corFonte : null}}
                    disabled={disabled}
                    onExit={onExit}
                    onKeyDown={ (e) => { pressP(e); handleEnter(e, next)}}
                    onKeyUp={ (e) => { onKeyUp(e) }}
                    onBlur={ onExit ? () => { onExit() } : null } 
                    onFocus={(e) => (e.target.select(), onEnter ?  onEnter() : null) }

                />
                </>}
                { porcento && <>
                    <MaskedInput name={ name ? name : null }
                    rightToLeft={true}
                    className={createClass(modelo ? modelo : 1) + (pulse ? " bmolder-input ": "")}
                    style={{ backgroundColor: cor ? cor : null, textAlign:txPos, fontSize: fontSize ? fontSize : null, color: corFonte ? corFonte : null}}
                    mask={ currencyMask2 } 
                    id={idd}
                    value={floatToReal(value, 3, "","", true) }
                    disabled={disabled} 
                    onKeyDown={ (e) => { pressP(e); handleEnter(e, next)}}
                    onKeyUp={ (e) => { onKeyUp(e)
                    }}
                    onBlur={ onExit ? () => { onExit() } : null } 
                    onChange={(e) => {
                        //toast.success(floatToReal(realToFloat("5.008", 0, 3), 3, "","", true))
                            setValue(realToFloat(e.target.value, 0, 3) ); 
                     
                       
                     }} 
                    onFocus={(e) => (e.target.select(), onEnter ?  onEnter() : null) }
                    inputMode='numeric'
                      
                /> 
                <div style={{height:"100%", display:"flex", alignItems:"center"}}>
                    <p style={{fontSize:"16px", marginRight:"4px", fontFamily:"sans-serif"}}>%</p>
                </div>
                </>}
             
            </BMolder>

    )

}

export const BCombo = ({children, hor_small, titulo, full, small, lado, setValue, value, reef, next, style="", setKey, setAux, width, height="46px"}) => {
    //const { signIn, loading, setUser, user } = useContext(AppContext);

 
    const getClassItem = () => { 
            if(full){ 
                return("bedit-row-item-full") 
            }else if(small){
                return("bedit-row-item-small")
            }else if(lado){
                    return("bedit-row-item-lado")
                }else if(hor_small){
                    return("bedit-row-item-hor-small")
            }else{
                return("bedit-row-item")
            }       
        }

    return(

            <div className={ getClassItem() } style={{width: width && width, height:height }}>
                { titulo  && <div className="bedit-item-title">{titulo}</div> }
                <select className='bedit-input' type="text"  ref={reef} style={style ? style  : {}} onKeyDown={ (e) => handleEnter(e, next)} 
                 onChange={(e) => { 
                     if(setKey || setAux){
                        let pr = JSON.parse(e.target.value);        
                        setValue(e.target.value); 
                        setKey && setKey(pr.key); 
                        setAux && setAux(pr.aux);
                     }else{
                        setValue(e.target.value); 
                     }
                   
             
                    }} value={value}>
                 <option>Selecione</option> 
                 {children}
                </select>
            </div>
    )
}
     

export const BCombo2 = ({modelo=1, id, name, children, tam="", titulo="", setValue, className,  widthListAuto=true, value, reef, next, cor="white", bordaCor, setKey, width, disabled, 
                         minWidth, autoWidth, multiline, marginTop, height, carregando=false, btnExtraTx="", atencao,
                         btnExtraHint="",btnExtraClick, pulse=true, onChange=()=>{}}) => {
 
    const [forcePulse, setForcePulse] = useState(false);

   
    function createClass(mdl){
            let base = "";
            let classe = "";
            //---------------------------------------
            if(mdl == 1){ classe = "bedit-model-1"; }
            else if(mdl == 2){ classe = "bedit-model-2"; }
            else{ classe = "bedit-model-3"; }    
            //---------------------------------------  cor ? cor : null autoComplete={"off"}
            return(classe + " " +base)
        }


      
    return(
        <BMolder  modelo={modelo} className={className} marginTop={marginTop} combo2="5px" width={width ? width : null} height={height ? height : null}
                minWidth={minWidth} bordaCor={bordaCor} bordaSize={"3px"} cor={cor}
                style={{overflow:"hidden"}} pulse={forcePulse && pulse}
                titulo={titulo && <div style={{display:"flex", justifyContent:"space-between", overflow:"hidden"}}>
                                                                                <p>{titulo}</p>
                                                                                { btnExtraClick && <>
                                                                                <div className='bcombo-btn-extra'  
                                                                                     style={{marginTop:"2px", marginRight:"5px"}} >
                                                                                    <Tooltip title={btnExtraHint} >
                                                                                        <AiFillSetting size={17} onClick={()=> {btnExtraClick();}} />
                                                                                    </Tooltip>
                                                                                </div></>}
                                                                            </div>} disabled={disabled}>
                { atencao && atencao == value && <>
                <Icon arq={aten_a} size={"28px"} marginLeft={"2px"} marginRight={"2px"} />
                </> }
                <div style={{ height:"100%", width:"100%", overflow:"hidden", paddingRight:"16px", marginLeft:"-11px",}}  autocomplete="off" >                                                                          
                    <Select name={name} id={id} disabled={disabled}  dropdownMatchSelectWidth={widthListAuto} autocomplete="off" dropdownStyle={{maxWidth:"480px"}}
                        onFocus={(e)=>{setForcePulse(true)}}
                        onBlur={(e)=>{setForcePulse(false)}}
                        className={createClass(modelo ? modelo : 1)+(pulse ? " bmolder-input ": "")}
                        optionFilterProp="children"
                        showSearch
                        loading={carregando} 
                        style={{height:"93%", width:"calc(100% + 28px)", marginTop:"2px", 
                                color:(value == -1 || value == "-1" || value == "") ? "rgb(150, 150, 150)" : null }} type="text" 
                        ref={reef} onKeyDown={ (e) => handleEnter(e, next)} 
                        onChange={(e) => { setValue(e); onChange(e); }} value={value}>
                        {children}
                    </Select>
                </div>
                
            </BMolder>
    )

}
export const BComboOp = ({children, value={}}) => {
    return(
        <Option value={value} ><div style={{width:"250px" ,minWidth:"250px", }}>{children}</div></Option> 
    
    );
}

export const BCirc = ({cor="green", cor_b="", left, top, size}) => {
    return(<>
        { cor_b ? <>
            <div className="bcirculo_a" style={{backgroundColor: "white", border: cor_b ? "2px solid "+cor_b : "", }}>
            <div className="bcirculo_b" style={{backgroundColor: cor ? cor : "green",}}/>  
        </div> 
        </> : <>
            <div className="bcirculo" style={{backgroundColor:cor ? cor : "green", marginLeft:left ? left : null, marginTop: top ? top : null, height: size ? size : null, width: size ? size : null}}/> 
        </> }
        </>
    );
}

export const BCheck = ({titulo, full, small, small2, small3, disabled=false, setValue, cor, fundo="rgba(255, 255, 255, 0)", value, reef, next, pulse=false,fontSize, width="auto", textPos="end", onClick=()=>{}}) => {
    //const { signIn, loading, setUser, user } = useContext(AppContext);

 
    const getClassItem = () => { 
            if(full){ 
                return("bedit-row-check-small") 
            }else if(small){
                return("bedit-row-check-small")
            }else if(small2){
                return("bedit-row-check-small")
            }else if(small3){
                return("bedit-row-check-small3")
            }else{
                return("bedit-row-check")
            }       
        }

    return(
        <BMolder modelo={3} width={width} minWidth={width}  pulse={pulse} cor={fundo}
                 bordaSize={1}  
                 disabled={disabled} >
                <div style={{ display:"flex", height:"100%", width:"100%",alignItems:"center", padding:"5px", borderRadius:"10px" ,
                                backgroundColor:fundo}}>

                {textPos == "start" &&  <div className="bedit-item-title-lateral" style={{color: cor ? cor : "black", fontSize:fontSize ? fontSize : "12px" }} 
                                             onClick={() => {  if(disabled == false){ setValue(!value); onClick(!value) } } }>{titulo}</div>}
               
                <input type="checkbox" disabled={disabled} style={{ marginLeft: textPos == "start" ? "5px" :"0px",  marginRight: textPos == "end" ? "5px" :"0px", 
                                                minHeight:"20px", maxHeight:"20px", minWidth:"20px", maxWidth:"20px", 
                                                 }} 
                       onChange={(e) => { if(disabled == false){  setValue(!value); onClick(!value) } }} checked={value} ref={reef} onKeyDown={ (e) => handleEnter(e, next)} 
                className='bedit-check' id="chk_a35235235tivo" name="chk_ativ2352352o" /> 
                {textPos == "end" &&  <div className="bedit-item-title-lateral" style={{color: cor ? cor : "black", fontSize:fontSize ? fontSize : "12px" }} 
                                            onClick={() => { if(disabled == false){  setValue(!value); onClick(!value) } } }>{titulo}</div>}
            </div>
        </BMolder>
    )

}

// [ {  tipo:"2", filtro_value:"1", filtro_col: "id_aux" } ]






function convertCorToRgb(cor){
    if(cor.length <= 6){
        if(cor == "black"){
            return "rgb(15, 15, 15)";
        }else if(cor == "black"){
            return "rgb(15, 15, 15)";
        }else if(cor == "blue"){
            return "rgb(17, 23, 220)";
        }else if(cor == "green"){
            return "rgb(23, 196, 0)";
        }else if(cor == "orange"){
            return "rgb(235, 106, 0)";
        }else if(cor == "red"){
            return "rgb(236, 0, 0)";
        }else if(cor == "yellow"){
            return "rgb(224, 221, 41)";
        }else if(cor == "gray"){
            return "rgb(104, 104, 104)";
        }else if(cor == "white"){
            return "rgb(255, 255, 255)";
        }else if(cor == "pink"){
            return "rgb(249, 21, 181)";
        }else if(cor == "purple"){
            return "rgba(176, 16, 216, 1)";
        }else if(cor == "grey"){
            return "rgb(81, 81, 81)";
        }
    }
    return cor;
}



export const BToggle = ({height="29px", width="100px", itens=[], value=-1, setValue=()=>{}, fundo="rgb(255, 255, 255)", borda=true,
                         color="black", corFontDis="black" }) => {
    return(<>       
        <div style={{ minWidth: width, height: height, border: borda ? "1px solid rgb(98, 98, 98)" : null, 
                      borderRadius:"5px", padding:"2px", display:"flex", gap:"2px",
           
                      margin:0, flexDirection:"row", backgroundColor: fundo, 
                      alignItems:"center", justifyContent:"center", color:color }}>
            { itens.length > 0 ? <>
                { itens.map(a => <div style={{color:corFontDis}}
                    className={`b-toggle-btn ${ value == a.value ? "b-toggle-sel" : "" } `} onClick={()=>{setValue(a.value)}}>{a.label}</div>) }
            </>:<p>Sem Itens</p> }
        </div>
    </>)
    
}


export const B2Button = ({height="100px", width="100px", texto, bt1_icon, bt1_hint, bt1_click = ()=>{}, bt2_icon, bt2_hint, bt2_click = ()=>{}}) => {
 
    return(<>       
        <div style={{ minWidth: width, height: height, border:"1px solid rgb(186, 201, 216)", borderRadius:"5px", display:"flex", 
                      margin:0, flexDirection:"column", backgroundColor: "rgb(237, 237, 237)" }}>
            <p style={{minWidth: width, display:"flex",alignItems:"center", justifyContent:"center",  marginTop:"0px", minHeight:"14px",  height:"100%",fontSize:"12px", overflow:"hidden"}}>{texto}</p>
            <div style={{minWidth: width, display:"flex", margin:0, justifyContent:"center", height:"100%",}}>
                <Tooltip title={bt1_hint}>
                <div className='b2button-btn' style={{borderTop:"1px solid rgb(222, 229, 236)", borderRight:"1px solid rgb(193, 201, 208)",  borderEndStartRadius:"5px", 
                            backgroundColor:"rgb(99, 130, 143)", color:"white", width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center", cursor:"pointer"}} 
                    onClick={() => { bt1_click() }} texto={bt1_hint} txTipo={'hint'} >{bt1_icon ? bt1_icon : null}</div>
                </Tooltip>
                <Tooltip title={bt2_hint}>
                <div className='b2button-btn' style={{borderTop:"1px solid rgb(222, 229, 236)",  borderEndEndRadius:"5px", 
                            backgroundColor:"rgb(99, 130, 143)", color:"white", width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center", cursor:"pointer"}} 
                    onClick={() => { bt2_click() }} texto={bt2_hint} txTipo={'hint'} >{bt2_icon ? bt2_icon : null}</div>
                </Tooltip>
            </div>
        </div>
    </>)
    
}

export const BButton = ({icon,  fundo="rgb(250, 254, 255)", cor="black", sel="blue", padding, name, fontSize="12px",
                        borda=true, bordaCor="rgb(183, 183, 183)", bordaSize=1, texto, novaAba=false, emAlerta=false, keyAction="", keyActionDouble=true, action,
                        height="37px", width="auto", minWidth, disabled, carregando=false, check=false, marginLeft, semFoco=false,
                        onClick=()=>{}, txTipo, iconPos="start", to, help=""}) => {
               
    let bordaSizeTmp = bordaSize;
    if(check){
        bordaSizeTmp += 1;
    }

    //const brd = String(bordaSize)+"px solid "+((disabled || carregando) ? "grey": check ? sel : bordaCor);
    const corr = String(((disabled || carregando) ? "grey": check ? sel : convertCorToRgb(bordaCor) ))
    const cor_fraca = corr.replace("rgb","rgba").replace(")",", 0.4)")
    const brd =  String(bordaSize)+"px solid "+cor_fraca;
    const brd2 =  String(bordaSizeTmp)+"px solid "+corr;
  //  border: 1px solid  rgb(239, 239, 239);
  //  border-bottom: 1px solid rgb(202, 202, 202);

    const [isKeyPressed, setIsKeyPressed] = useState(false);
    const [qtdPress, setQtdPress] = useState(0);

    useEffect(() => {
        setQtdPress(0);
    }, []);


    const TimerActionComponent = ({keyAction="", double=true, isKeyPressed, setIsKeyPressed, action=()=>{} }) => {
        useEffect(() => {
            let timeoutId;
    
            const handleKey = (event) => {
                
                //toast.error(event.key+" | "+String(qt)+ " | "+String(qtdPress))
                if(event.key == keyAction){   
                    if(double){
                        setQtdPress(qtdPress+1); 
                        if(!timeoutId){
                            if(qtdPress >= 1){
                                clearTimeout(timeoutId);
                                setIsKeyPressed(false);
                                setQtdPress(0); 
                          
                                action();
                            }else if(qtdPress == 0){
                                setIsKeyPressed(true);
                                timeoutId = setTimeout(() => {   
                                    clearTimeout(timeoutId);
                                    setIsKeyPressed(false);
                                    setQtdPress(0); 
                                }, 400);
                            }              
                        }
                    }else{
                        clearTimeout(timeoutId);
                        setIsKeyPressed(false);
                        setQtdPress(0); 
                        action();
                    }
                }
            };
            
        
            window.addEventListener('keyup', handleKey);  
            return () => { 
                window.removeEventListener('keyup', handleKey);
            // clearTimeout(timeoutId);
            };
        }, []);
  
        return(<></>);
    };

    const idTemp = Math.random();
    //TIPO: hint hide check hide/check
    // Na altura Heigth usar numeros PAREDS sempre 28 , 40 , 20 , 22 , 24
    return(<>
    {
     
    keyAction && safeStr(keyAction).length >= 1 ? <TimerActionComponent 
    keyAction={keyAction} 
    double={keyActionDouble}
    isKeyPressed={isKeyPressed} setIsKeyPressed={setIsKeyPressed} action={ () => { action() }} /> : null
    
    }
    
        { (disabled || carregando) ? 
        <>
            <BSpinDiv item_pai={"div-"+idTemp} style={{ marginLeft: "3px", height: height, width: width, paddingLeft: padding ? padding : null, marginLeft : marginLeft ? marginLeft : null, 
                         paddingRight: padding ? padding : null, }} texto={""}  display={carregando ? true : false} carregando={carregando} opacidade={"0.3"}>
            { texto && txTipo == "hint" ?
                <button id={idTemp} className="b-button" onClick={onClick} tabIndex={semFoco ? "-1" : null}
                    style={{ fontSize:fontSize , height: height, width: width, paddingLeft: padding ? padding : null, paddingRight: padding ? padding : null,
                    color:"grey", border: borda? brd : 'none', backgroundColor: fundo }} disabled={true}> {icon && <div className="b-button-icon" >{icon}</div>}
                </button> 
            : texto && txTipo == "hide" ?
                    <button id={idTemp} className="b-button b-button-tx-auto-hide" onClick={onClick} tabIndex={semFoco ? "-1" : null}
                        style={{fontSize:fontSize ,  height: height, width: width, paddingLeft: padding ? padding : null, paddingRight: padding ? padding : null,
                        color:"grey", border: borda? brd : 'none', backgroundColor: fundo }} disabled={true}>{icon && <div className="b-button-icon">{icon}</div>}
                        <div className="txtx" >{texto}</div>      
                    </button>
            
                : texto && txTipo == "hide/check" ?
                    <button id={idTemp} className={`b-button ${ !check && "b-button-tx-auto-hide"  } `} onClick={onClick} tabIndex={semFoco ? "-1" : null}
                        style={{fontSize:fontSize ,  height: height, width: width, paddingLeft: padding ? padding : null, paddingRight: padding ? padding : null,
                        color:"grey", border: borda? brd : 'none', backgroundColor: fundo }} disabled={true}>{icon && <div className="b-button-icon">{icon}</div>}              
                        { check ? <div className="b-button-texto">{texto}</div> : <div className="txtx" >{<div className="b-button-texto">{texto}</div>}</div>    }                                      
                    </button>
                    :
                    <button id={idTemp} className="b-button" onClick={onClick} tabIndex={semFoco ? "-1" : null}
                        style={{fontSize:fontSize ,  height: height, width: width, paddingLeft: padding ? padding : null, paddingRight: padding ? padding : null,
                        color:"grey", border: borda? brd : 'none', backgroundColor: fundo }} disabled={true}>{icon && <div className="b-button-icon">{icon}</div>}
                        { texto ? ( txTipo == "check" ? ( check ? <div className="b-button-texto">{texto}</div> : null ) : (<div className="b-button-texto">{texto}</div>) ) : null }                     
                    </button>
            }
            </BSpinDiv></>
            :
            <>
            { texto && txTipo == "hint" ?
            <> 
           
                { to ?  <Tooltip title={texto}>
                    <Link id={idTemp} className="b-button b-normal" onClick={onClick} target={novaAba ? "_blank" : null} to={to} name={name ? name : null} tabIndex={semFoco ? "-1" : null}
                        style={{fontSize:fontSize ,  height: height, width: width, paddingLeft: 0, paddingRight: 0,
                        color:cor, borderTop: borda? brd : 'none', borderLeft: borda? brd : 'none', borderRight: borda? brd : 'none', borderBottom: borda? brd2: 'none', backgroundColor: fundo }} >{icon && <div className="b-button-icon" >{icon}</div>}
                    </Link> 
                </Tooltip> : <Tooltip title={texto}> 
                    <button id={idTemp} className="b-button b-normal" onClick={onClick} name={name ? name : null} tabIndex={semFoco ? "-1" : null}
                        style={{ fontSize:fontSize, height: height, width: width, borderRadius:"8px",
                                 paddingLeft: 0, paddingRight: 0, alignItems:"center", justifyContent:"center",
                        color: cor, 
                        borderTop: borda? brd : 'none', borderLeft: borda? brd : 'none', borderRight: borda? brd : 'none', borderBottom: borda? brd2: 'none', 
                         backgroundColor: fundo }} >{icon && <div className="b-button-icon" style={{marginRight:"0px"}}>{icon}</div>}
                    </button> 
                    </Tooltip>  }              
            </>           
            : texto && txTipo == "hide" ?
                    to ? <>
                        <Link id={idTemp} className="b-button b-normal b-button-tx-auto-hide" onClick={onClick} target={novaAba ? "_blank" : null} to={to} name={name ? name : null} tabIndex={semFoco ? "-1" : null}
                            style={{fontSize:fontSize ,  height: height, marginLeft : marginLeft ? marginLeft : null, width: width, paddingLeft: padding ? padding : null, paddingRight: padding ? padding : null,
                            color:cor, borderTop: borda? brd : 'none', borderLeft: borda? brd : 'none', borderRight: borda? brd : 'none', borderBottom: borda? brd2: 'none', 
                             backgroundColor: fundo }} >{icon && <div className="b-button-icon">{icon}</div>} 
                            <div className="txtx" >{texto}</div>      
                        </Link>
                    </> : <>
                        <button id={idTemp} className="b-button b-normal b-button-tx-auto-hide" onClick={onClick}  name={name ? name : null} tabIndex={semFoco ? "-1" : null}
                            style={{ fontSize:fontSize , height: height,marginLeft : marginLeft ? marginLeft : null,  width: width, paddingLeft: padding ? padding : null, paddingRight: padding ? padding : null,
                            color:cor, borderTop: borda? brd : 'none', borderLeft: borda? brd : 'none', borderRight: borda? brd : 'none', borderBottom: borda? brd2: 'none', 
                             backgroundColor: fundo }} >{icon && <div className="b-button-icon">{icon}</div>}
                            <div className="txtx" >{texto}</div>      
                        </button>
                    </> 
                : texto && txTipo == "hide/check" ?
                    to ? <>
                        <Link id={idTemp} className={`b-button b-normal ${ !check && " b-button-tx-auto-hide"  } `} tabIndex={semFoco ? "-1" : null} onClick={onClick} target="_blank" to={to} name={name ? name : null}
                            style={{fontSize:fontSize ,  height: height,marginLeft : marginLeft ? marginLeft : null,  width: width, paddingLeft: padding ? padding : null, paddingRight: padding ? padding : null,
                            color:cor, 
                            borderTop: borda? brd : 'none', borderLeft: borda? brd : 'none', borderRight: borda? brd : 'none', borderBottom: borda? brd2: 'none',  backgroundColor: fundo }} >{icon && <div className="b-button-icon">{icon}</div>}                 
                            { check ? texto : <div className="txtx" >{texto}</div>    }                                      
                        </Link>
                    </> : <>
                        <button id={idTemp} className={`b-button b-normal ${ !check && " b-button-tx-auto-hide"  } `} tabIndex={semFoco ? "-1" : null} onClick={onClick} name={name ? name : null}
                            style={{fontSize:fontSize ,  height: height, marginLeft : marginLeft ? marginLeft : null, width: width, paddingLeft: padding ? padding : null, paddingRight: padding ? padding : null,
                            color: cor, 
                            borderTop: borda? brd : 'none', borderLeft: borda? brd : 'none', borderRight: borda? brd : 'none', borderBottom: borda? brd2: 'none'
                            , backgroundColor: fundo }} >{icon && <div className="b-button-icon">{icon}</div>}                 
                            { check ? texto : <div className="txtx" >{texto}</div>    }                                      
                        </button>
                    </> 
                    :
                    to ? <>
                        <Link id={idTemp} className={`b-button b-normal `} onClick={onClick} target={novaAba ? "_blank" : null} tabIndex={semFoco ? "-1" : null} to={to} name={name ? name : null}
                            style={{fontSize:fontSize ,  height: height, marginLeft : marginLeft ? marginLeft : null, width: width, paddingLeft: padding ? padding : null, paddingRight: padding ? padding : null,
                            color:cor, border: borda? brd : 'none', backgroundColor: fundo }} >{icon && <div className="b-button-icon">{icon}</div>}
                            { texto ? ( txTipo == "check" ? ( check ? <div className="b-button-texto">{texto}</div> : null ) : (<div className="b-button-texto">{texto}</div>) ) : null }                     
                        </Link>
                    </> : <> 
                        <button id={idTemp} 
                            className={"b-button b-normal "+(isKeyPressed ? " b-anima " : "")+" "+(emAlerta ? " b-button-borda " : "") } 
                            tabIndex={semFoco ? "-1" : null} onClick={onClick} name={name ? name : null} 
                            style={{fontSize:fontSize , height: height, width: width, minWidth: minWidth ? minWidth : null,  marginLeft : marginLeft ? marginLeft : null, paddingLeft: padding ? padding : null, paddingRight: padding ? padding : null,
                            color: cor, 
                           
                            borderTop: borda? brd : 'none', borderLeft: borda? brd : 'none', borderRight: borda? brd : 'none', borderBottom: borda? brd2: 'none', 
                            
                            
                            backgroundColor: fundo }} >{icon && iconPos == "start" && <div className="b-button-icon" style={{}}>{icon}</div>}
                            { texto ? ( txTipo == "check" ? ( check ? <div className="b-button-texto">{texto}</div> : null ) : 
                                                            (<div className="b-button-texto" style={{}}>{texto}</div>) ) : null }   
                            {icon && iconPos == "end" && <div className="b-button-icon-end" style={{}}>{icon}</div>}  
                            {String(help).length > 0 && <div><Tooltip title={String(help)} 
                                        zIndex={999999}><FaQuestionCircle size={14} color={"rgb(69, 106, 121)"} 
                                        style={{marginLeft:"5px"}}/></Tooltip></div> }                
                        </button>
                    </>
            }
            </>
        }
        
    </>)

}


export const BButtonCheck = ({icon, fundo="rgb(250, 254, 255)", cor="black", sel="blue", padding, name, fontSize="12px",
                        borda=true, bordaCor="rgb(183, 183, 183)", bordaSize=1, texto, novaAba=false, emAlerta=false, keyAction="", action,
                        height="37px", width="auto", disabled, carregando=false, check=false, marginLeft, semFoco=false,
                        onClick=()=>{}, txTipo, to}) => {

    let bordaSizeTmp = bordaSize;
    if(check){
        bordaSizeTmp += 2;
    }

    const idTemp = Math.random();

    //const brd = String(bordaSize)+"px solid "+((disabled || carregando) ? "grey": check ? sel : bordaCor);
    const corr = String(((disabled || carregando) ? "grey": check ? sel : convertCorToRgb(bordaCor) ))
    const cor_fraca = corr.replace("rgb","rgba").replace(")",", 0.4)")
    const brd =  String(bordaSize)+"px solid "+cor_fraca;
    const brd2 =  String(bordaSizeTmp)+"px solid "+corr;
  //  border: 1px solid  rgb(239, 239, 239);
  //  border-bottom: 1px solid rgb(202, 202, 202);

    //TIPO: hint hide check hide/check
    // Na altura Heigth usar numeros PAREDS sempre 28 , 40 , 20 , 22 , 24
    return(<>
        { (disabled || carregando) ? 
        <>
            <BSpinDiv item_pai={"div-"+idTemp} style={{ marginLeft: "3px", height: height, width: width, paddingLeft: padding ? padding : null, marginLeft : marginLeft ? marginLeft : null, 
                         paddingRight: padding ? padding : null, }} texto={""}  display={carregando ? true : false} carregando={carregando} opacidade={"0.3"}>
            { texto && txTipo == "hint" ?
                <button id={idTemp} className="b-button" onClick={onClick} tabIndex={semFoco ? "-1" : null}
                    style={{ fontSize:fontSize , height: height, width: width, paddingLeft: padding ? padding : null, paddingRight: padding ? padding : null,
                    color:"grey", border: borda? brd : 'none', backgroundColor: fundo }} disabled={true}> {icon && <div className="b-button-icon" >{icon}</div>}
                </button> 
            : texto && txTipo == "hide" ?
                    <button id={idTemp} className="b-button b-button-tx-auto-hide" onClick={onClick} tabIndex={semFoco ? "-1" : null}
                        style={{fontSize:fontSize ,  height: height, width: width, paddingLeft: padding ? padding : null, paddingRight: padding ? padding : null,
                        color:"grey", border: borda? brd : 'none', backgroundColor: fundo }} disabled={true}>{icon && <div className="b-button-icon">{icon}</div>}
                        <div className="txtx" >{texto}</div>      
                    </button>
            
                : texto && txTipo == "hide/check" ?
                    <button id={idTemp} className={`b-button ${ !check && "b-button-tx-auto-hide"  } `} onClick={onClick} tabIndex={semFoco ? "-1" : null}
                        style={{fontSize:fontSize ,  height: height, width: width, paddingLeft: padding ? padding : null, paddingRight: padding ? padding : null,
                        color:"grey", border: borda? brd : 'none', backgroundColor: fundo }} disabled={true}>{icon && <div className="b-button-icon">{icon}</div>}              
                        { check ? <div className="b-button-texto">{texto}</div> : <div className="txtx" >{<div className="b-button-texto">{texto}</div>}</div>    }                                      
                    </button>
                    :
                    <button id={idTemp} className="b-button" onClick={onClick} tabIndex={semFoco ? "-1" : null}
                        style={{fontSize:fontSize ,  height: height, width: width, paddingLeft: padding ? padding : null, paddingRight: padding ? padding : null,
                        color:"grey", border: borda? brd : 'none', backgroundColor: fundo }} disabled={true}>{icon && <div className="b-button-icon">{icon}</div>}
                        { texto ? ( txTipo == "check" ? ( check ? <div className="b-button-texto">{texto}</div> : null ) : (<div className="b-button-texto">{texto}</div>) ) : null }                     
                    </button>
            }
            </BSpinDiv></>
            :
            <>
            { texto && txTipo == "hint" ?
            <> 
           
                { to ?  <Tooltip title={texto}>
                    <Link id={idTemp} className="b-button" onClick={onClick} target={novaAba ? "_blank" : null} to={to} name={name ? name : null} tabIndex={semFoco ? "-1" : null}
                        style={{fontSize:fontSize ,  height: height, width: width, paddingLeft: padding ? padding : null, paddingRight: padding ? padding : null,
                        color:cor, borderTop: borda? brd : 'none', borderLeft: borda? brd : 'none', borderRight: borda? brd : 'none', borderBottom: borda? brd2: 'none', backgroundColor: fundo }} >{icon && <div className="b-button-icon" >{icon}</div>}
                    </Link> 
                </Tooltip> : <Tooltip title={texto}> 
                    <button id={idTemp} className="b-button" onClick={onClick} name={name ? name : null} tabIndex={semFoco ? "-1" : null}
                        style={{ fontSize:fontSize, height: height, width: width, paddingLeft: padding ? padding : null, paddingRight: padding ? padding : null,
                        color: cor, 
                        borderTop: borda? brd : 'none', borderLeft: borda? brd : 'none', borderRight: borda? brd : 'none', borderBottom: borda? brd2: 'none', 
                         backgroundColor: fundo }} >{icon && <div className="b-button-icon"style={{marginRight:"2px"}}>{icon}</div>}
                    </button> 
                    </Tooltip>  }              
            </>           
            : texto && txTipo == "hide" ?
                    to ? <>
                        <Link id={idTemp} className="b-button b-button-tx-auto-hide" onClick={onClick} target={novaAba ? "_blank" : null} to={to} name={name ? name : null} tabIndex={semFoco ? "-1" : null}
                            style={{fontSize:fontSize ,  height: height, marginLeft : marginLeft ? marginLeft : null, width: width, paddingLeft: padding ? padding : null, paddingRight: padding ? padding : null,
                            color:cor, borderTop: borda? brd : 'none', borderLeft: borda? brd : 'none', borderRight: borda? brd : 'none', borderBottom: borda? brd2: 'none', 
                             backgroundColor: fundo }} >{icon && <div className="b-button-icon">{icon}</div>} 
                            <div className="txtx" >{texto}</div>      
                        </Link>
                    </> : <>
                        <button id={idTemp} className="b-button b-button-tx-auto-hide" onClick={onClick}  name={name ? name : null} tabIndex={semFoco ? "-1" : null}
                            style={{ fontSize:fontSize , height: height,marginLeft : marginLeft ? marginLeft : null,  width: width, paddingLeft: padding ? padding : null, paddingRight: padding ? padding : null,
                            color:cor, borderTop: borda? brd : 'none', borderLeft: borda? brd : 'none', borderRight: borda? brd : 'none', borderBottom: borda? brd2: 'none', 
                             backgroundColor: fundo }} >{icon && <div className="b-button-icon">{icon}</div>}
                            <div className="txtx" >{texto}</div>      
                        </button>
                    </> 
                : texto && txTipo == "hide/check" ?
                    to ? <>
                        <Link id={idTemp} className={`b-button ${ !check && " b-button-tx-auto-hide"  } `} tabIndex={semFoco ? "-1" : null} onClick={onClick} target="_blank" to={to} name={name ? name : null}
                            style={{fontSize:fontSize ,  height: height,marginLeft : marginLeft ? marginLeft : null,  width: width, paddingLeft: padding ? padding : null, paddingRight: padding ? padding : null,
                            color:cor, 
                            borderTop: borda? brd : 'none', borderLeft: borda? brd : 'none', borderRight: borda? brd : 'none', borderBottom: borda? brd2: 'none',  backgroundColor: fundo }} >{icon && <div className="b-button-icon">{icon}</div>}                 
                            { check ? texto : <div className="txtx" >{texto}</div>    }                                      
                        </Link>
                    </> : <>
                        <button id={idTemp} className={`b-button ${ !check && " b-button-tx-auto-hide "  } `} tabIndex={semFoco ? "-1" : null} onClick={onClick} name={name ? name : null}
                            style={{fontSize:fontSize ,  height: height, marginLeft : marginLeft ? marginLeft : null, width: width, paddingLeft: padding ? padding : null, paddingRight: padding ? padding : null,
                            color: cor, 
                            borderTop: borda? brd : 'none', borderLeft: borda? brd : 'none', borderRight: borda? brd : 'none', borderBottom: borda? brd2: 'none'
                            , backgroundColor: fundo }} >{icon && <div className="b-button-icon">{icon}</div>}                 
                            { check ? texto : <div className="txtx" >{texto}</div>    }                                      
                        </button>
                    </> 
                    :
                    to ? <>
                        <Link id={idTemp} className={`b-button  `} onClick={onClick} target={novaAba ? "_blank" : null} tabIndex={semFoco ? "-1" : null} to={to} name={name ? name : null}
                            style={{fontSize:fontSize ,  height: height, marginLeft : marginLeft ? marginLeft : null, width: width, paddingLeft: padding ? padding : null, paddingRight: padding ? padding : null,
                            color:cor, border: borda? brd : 'none', backgroundColor: fundo }} >{icon && <div className="b-button-icon">{icon}</div>}
                            { texto ? ( txTipo == "check" ? ( check ? <div className="b-button-texto">{texto}</div> : null ) : (<div className="b-button-texto">{texto}</div>) ) : null }                     
                        </Link>
                    </> : <> 
                        <button id={idTemp} 
                            className={"b-button "+(emAlerta ? " b-button-borda " : "") } 
                            tabIndex={semFoco ? "-1" : null} onClick={onClick} name={name ? name : null} 
                            style={{fontSize:fontSize , height: height, width: width, marginLeft : marginLeft ? marginLeft : null, 
                                paddingLeft: padding ? padding : null, paddingRight: padding ? padding : null,
                                color: cor,  
                                borderTop: borda? (check ? brd2: brd ): 'none', 
                                borderLeft: borda? brd : 'none', 
                                borderRight: borda? brd : 'none', 
                                borderBottom: borda ? brd: 'none',                   
                                backgroundColor: check == true ? "#E9EEF3" : fundo }} >{icon && <div className="b-button-icon" style={{}}>{icon}</div>}
                            { texto ? ( txTipo == "check" ? ( check ? <div className="b-button-texto" >{texto}</div> : null ) : 
                                                            (<div className="b-button-texto" >{texto}</div>) ) : null }                     
                        </button>
                    </>
            }
            </>
        }
        
    </>)

}


export const BIcon = ({icon=1,title="", size="18px", cor="black", onClick = () => {}}) =>{
    //'#08c'
    //() => { setItemSel(e.id); setShowEdit(true);

    const i_1 = <SearchOutlined style={{ fontSize: size, color: cor, margin: "4px",
                cursor: onClick ? 'pointer' : 'default' }} 
                onClick={onClick}/>

                const i_2 = <LikeFilled   style={{ fontSize: size, color: cor, margin: "4px",
                cursor: onClick ? 'pointer' : 'default' }} 
                onClick={onClick}/>

                const i_3 = <LikeOutlined style={{ fontSize: size, color: cor, margin: "4px",
                cursor: onClick ? 'pointer' : 'default' }} 
                onClick={onClick}/>

                const i_4 = <DeleteFilled style={{ fontSize: size, color: cor, margin: "4px",
                cursor: onClick ? 'pointer' : 'default' }} 
                onClick={onClick}/>

                const i_5 = <DeleteOutlined style={{ fontSize: size, color: cor, margin: "4px",
                cursor: onClick ? 'pointer' : 'default' }} 
                onClick={onClick}/>

    return(<>
        <Tooltip title={title}>
            { icon == 1 && i_1 }
            { icon == 2 && i_2 }
            { icon == 3 && i_3 }
            { icon == 4 && i_4 }
            { icon == 5 && i_5 }
        </Tooltip>
    </>)
}


export const BMenuContext = ({children, className="", click=null, visivel=false }) => {
    //const { signIn, loading, setUser, user } = useContext(AppContext);

    const [anchorPoint, setAnchorPoint] = useState({ x: "0px", y: "0px" });
    const [show, setShow] = useState(false);

    let xPos ="";
    let yPos ="";

      document.addEventListener("contextmenu",
        useCallback(
          (event) => {
            event.preventDefault();
          
                xPos =(event.pageX -15) + "px";
                yPos =(event.pageY -15) + "px";
                setAnchorPoint({ x: xPos, y: yPos });
           
          },
          [setAnchorPoint]
        )
      );


    return(
        <>
     { visivel ?
        <div  className={"btec-menu-context "+className} style={{ top: anchorPoint.y, left: anchorPoint.x }}  onClick={ () => { click && click() }}>
          <ul className="btec-menu-context-list">
            { children }    
          </ul>
        </div>
        :
        <></>
    }
    </>
    )

}

export const BMenuContextItem = ({children, className="", click=null }) => {

    return(
     
        <li className={"btec-menu-context-item "+className}> 
            <button className="btec-menu-context-item-btn" onClick={ () => { click && click() }}>      
            { children } 
            </button>      
        </li>

    )

}


export const BDisplayGrid = ({children, height="", cols=8, style}) => {
    return(
        <div className={"btec-display-grid"} style={{...style, height:height.length >= 2 ? height : "auto", gridTemplateColumns: "repeat("+cols+", 82px)"}}> 
            {children}
        </div>
    )
}

export const BDisplayValor = ({titulo = "", valor = "0,00", cor="#605873", tipo="valor", borda, fundo, tag=true, limit=13,  width, onClick }) => {
    const estilo = {
        backgroundColor: cor,
        
    }

    function trataT(tx="", tp){
        let res = tx;
        if(tp == "valor"){
        
        }else if(tp == "qtd"){
            if(res.length == 3){
                res = res.replace(".0","")
                res = res.replace(",0","")
            }
            if(res.length == 4){
                res = res.replace(".00","")
                res = res.replace(",00","")
            }
            res = res.replace(".000","").replace(".0000","")
            res = res.replace(",000","").replace(",0000","")
        }else if(tp == "porcento"){
            if(res.length == 3){
                res = res.replace(".0","")
                res = res.replace(",0","")
            }
            if(res.length == 4){
                res = res.replace(".00","")
                res = res.replace(",00","")
            }
            res = res.replace(".000","").replace(".0000","")
            res = res.replace(",000","").replace(",0000","")
            if(res.length <= 0){
                res = "0"
            }
            res = res+"%"
        }
        return res;
    }

    return(<>
        { titulo.length > limit ? <Tooltip title={titulo}> 
        <div className={"btec-display-valor-box"} style={{width: width ? width : null, 
             backgroundColor:fundo ? fundo : null, cursor:onClick ? "pointer" : "default"}} onClick={ onClick ? ()=> onClick() : null} > 
                {tag ? <> <div className={"btec-display-valor-tag"} style={estilo}></div></> : <></>}
                <div className={"btec-display-valor-content"} > 
                              
                    <div className={"btec-display-valor-titulo"} > 
                            {titulo}
                    </div>
                    <div className={"btec-display-valor-valor"} >               
                            {trataT(String(valor), tipo)}
                    </div>
                 </div>

        </div>   
        </Tooltip>
        :        
        <div className={"btec-display-valor-box"}  style={{width: width ? width : null, border: borda ? ("1px solid "+borda) : null, 
                    backgroundColor:fundo ? fundo : null, cursor:onClick ? "pointer" : "default"}} onClick={ onClick ? ()=> onClick() : null} >
        {tag ? <> <div className={"btec-display-valor-tag"} style={estilo}></div></> : <></>}
        <div className={"btec-display-valor-content"} > 
                     
           <div className={"btec-display-valor-titulo"} > 
                {titulo}
           </div>
           <div className={"btec-display-valor-valor"} > 
                {trataT(String(valor), tipo)}
           </div>
        </div>
        </div>
        }
    </>)

}

export const BSelecter = ({name="", carregando=false, autoFoco=false, selected=-1, itens=[], onClick=() => {}}) => {
    const [ sel, setSel ] = useState(selected);//texto selecionado combo
    // itens={[ { titulo:"Retira na loja", id:1 }, { titulo:"Enviar para Cliente", id:2 } ]}
    return(<>
        <button name={name} style={{margin: 0, padding: 0, border: "none"}}
            className={`selecter_content ${ autoFoco && 'selecter_borda' } `} 
            onKeyUp={(e)=>{
                //toast.success(e.key)
                if(e.key == "ArrowDown"){
                    e.preventDefault();
                    setSel(sel + 1)
                }
                if(e.key == "ArrowUp"){
                    e.preventDefault();
                    setSel(sel - 1)
                }
                if(e.key == "Enter" || e.key == "Next" || e.key == "Go" || e.key == "Okay" || e.key == "Ok" ){
                    e.preventDefault();
                    let item = itens.find( p => p.id == sel);
                    if(item){
                       onClick(sel, item)
                    }
                    
                }
            }} >
            <Spin style={{'height':'100%'}} spinning={carregando} tip="Carregando...">
                { itens.map( e => { if(isObjectEmpty(e) == false){ return(
                    <div className={`selecter_btn selecter_bottom ${ sel == e.id && 'selecter_selected' } `} 
                            onClick={ () => {setSel(e.id); onClick(e.id, e)}} >
                        <div className="selecter_titulo">
                             {e.titulo && e.titulo}
                             {e.nome && e.nome}
                             {e.icon && e.icon}
                        </div>
                    </div>  
                )} }) }
            </Spin>
        </button>
    </>)
}


