import React, {useEffect, useState, useContext, useRef} from 'react';
import { AppContext } from '../../context';
import { toast } from 'react-toastify';
import api from '../../services/api';

import { FiSearch,FiEdit2, FiPackage, FiRefreshCcw } from 'react-icons/fi';
import { BAppContent, BButton, BCheck, BEdit, BPassw, BSpinDiv } from '../../components/BeiTec';
import './PontoQr.css';
import { asyncGet, asyncGetFile, asyncSendFile, delay, floatToReal, safeFloat, safeInt, safeStr } from '../../services/utils';
import { Link, useParams, useNavigate } from 'react-router-dom';
import Modal from '../../components/Modal/Modal';
import logo from '../../assets/logo-pix-256.png';
import logo_inter from '../../assets/logo-inter.png';

import { BsFillCreditCard2BackFill } from 'react-icons/bs';
import { FaCamera, FaCcVisa, FaCheck  } from 'react-icons/fa';
import { AiOutlineBarcode  } from 'react-icons/ai';
import { SiMastercard } from 'react-icons/si';
import { TbExternalLink } from 'react-icons/tb';
import { MdArrowBackIos, MdContentCopy, MdDownload } from 'react-icons/md';

import Webcam from 'react-webcam';

import { osVersion, osName, fullBrowserVersion, browserVersion, browserName, 
    mobileVendor, mobileModel, engineName, engineVersion, deviceType} from 'react-device-detect';
import { QRCode } from 'antd';
import Barcode from 'react-barcode';
import fileDownload from 'js-file-download';
import { GiBackwardTime, GiPositionMarker } from 'react-icons/gi';
import { TiCameraOutline } from 'react-icons/ti';
import { GrNext } from 'react-icons/gr';


export default function PontoQr({children}){
    const { hash } = useParams();
    const [ infos, setInfos ] = useState({});

    const [screenshot, setScreenshot] = useState(null);

    const [verCamera, setVerCamera] = useState(true);

    
    const [carregando, setCarregando] = useState(false);

    const [aceito, setAceito] = useState(false);
    const [obs, setObs] = useState("");
    const [uPasse, setUPasse] = useState("");
    
    const [erroTx, setErroTx] = useState("");

    
    const [permiteCamera, setPermiteCamera] = useState(false);
    const [permitePos, setPermitePor] = useState(false);

    const [page, setPage] = useState(9); // 0 iniciando, 1 registra, 2 expirado, 3 concluido
    
    const checkCameraPermission = async () => {
        try {
          const stream = await navigator.mediaDevices.getUserMedia({ video: true });
          // Ação caso a permissão esteja concedida.
          setPermiteCamera(true);
          if(stream){
            stream.getTracks().forEach((track) => track.stop());
          }
        
        } catch (error) {
            setPermiteCamera(false);
          // Ação caso a permissão não esteja concedida.
        }
      };
      
      const checkLocationPermission = async () => {

          navigator.geolocation.getCurrentPosition(()=>{
            setPermitePor(true)
          }, ()=>{
            setPermitePor(false)
          });
          // Ação caso a permissão esteja concedida.
        
      
      };

    function decodeBase64Image(dataString) {
        var matches = dataString.match(/^data:([A-Za-z-+\/]+);base64,(.+)$/),
          response = {};
      
        if (matches.length !== 3) {
          return new Error('Invalid input string');
        }
      
        response.type = matches[1];
        response.data = new Buffer(matches[2], 'base64');
      
        return response;
      }

    const captureScreenshot = () => {

      const screenshot = webcamRef.current.getScreenshot(); 
      //alert(JSON.stringify(decodeBase64Image(screenshot)))
      setScreenshot(screenshot);
    };
  
    const webcamRef = useRef(null);
  

    function getDms(val) {
        var valDeg, valMin, valSec, result;

        val = Math.abs(val);

        valDeg = Math.floor(val);
        result = valDeg + "º";

        valMin = Math.floor((val - valDeg) * 60);
        result += valMin + "'";

        valSec = Math.round((val - valDeg - valMin / 60) * 3600 * 1000) / 1000;
        result += valSec + '"';

        return result;
    }

    // conversion string in DD to DMS.
    function ddToDms(lat, lng) {
        var latResult, lngResult, dmsResult;

        lat = parseFloat(lat);
        lng = parseFloat(lng);

        latResult = (lat >= 0)? 'N' : '';
        // Call to getDms(lat) function for the coordinates of Latitude in DMS.
        // The result is stored in latResult variable.

        latResult += getDms(lat);
        lngResult = (lng >= 0)? 'L' : '';

        // Call to getDms(lng) function for the coordinates of Longitude in DMS.
        // The result is stored in lngResult variable.
        lngResult += getDms(lng);

        // document.getElementById("latMap").value = latResult;
        // document.getElementById("lngMap").value = lngResult;
        return String(latResult+"S+"+lngResult)
    }


    useEffect(() => {

        if(hash.length > 10){
            delay(()=>{
                asyncGet("pontoLerQR", { code: safeStr(hash) }, 
                (e)=>{     
                    setPage(9);
                    setInfos(e);
                }, false, ()=>{
                    setPage(3);
                });
            })
           
        }
        
    }, []);   
    
    useEffect(() => {
        if(page == 9 || page == 1){
            checkLocationPermission();
            checkCameraPermission();
            
        }
        
    }, [page]);
    
    const videoConstraints = {
        width: 1280,
        height: 720,
        facingMode: "user"
      };

      function registrar(){
        
        const formData = new FormData();      
                    
        if(screenshot){
            const file = new File([decodeBase64Image(screenshot).data], String(hash)+'.jpeg', { type: 'image/jpeg' });
            formData.append("img", file);  
        }else{
            formData.append("img", ""); 
        }
        
        //const img = document.getElementById('img_tirada_24125');
        //const type = img.getAttribute('type');
        // alert(String(type))
        //const blob = new Blob(screenshot, { type: 'image/jpeg' });
        

        
        formData.append("senha", safeStr(uPasse));  
        formData.append("code", safeStr(hash)); 
        formData.append("obs", safeStr(obs)); 
        

        asyncSendFile("pontoReg", formData, (e)=>{ 
            
           if(String(e).length < 2){
               toast.error("Erro!")
               setPage(3);
           }else{
                if(String(e) == "OK"){
                    setPage(4);
                    toast.success("Registrado!")
                }else{
                    if(String(e) == "EXP"){
                        setPage(3);
                        toast.warn("Expirado!")
                    }else{
                        if(safeStr(e).indexOf("senha incorretos") > 0){
                            toast.warn("Usuário ou senha incorreto!");
                            setUPasse("");
                        }else{
                            setPage(3);
                            setErroTx(e);
                            toast.error("Erro...")
                        }
                   
                    }
                }
           }
      
        
        }, false, setCarregando);   

   
      }

    return(<>
        <div className="pontoqr-marca"/>
        <div className="pontoqr-content">
            <div style={{ width:"100%", fontSize:"15px", display:"flex", alignItems:"center", justifyContent:"start", padding:"14px", gap:"28px",
                          marginBottom:"28px", backgroundColor:"#a2a1a13d", borderBottom:"1px solid #b5b5b5"}}>
               <strong>BeiTec | REGISTRO DE PONTO v1.0.3</strong>  
            </div>
        </div>
        <BSpinDiv displa={carregando} className="pontoqr-content">
            
             { page == 0 && <>
                <div className="pontoqr-box" style={{height:"auto"}}>      
                Carregando
                </div>
            </>}
            { page == 9 && <>
                <div className="pontoqr-box" style={{height:"auto", display:"flex", flexDirection:"column", gap:"15px", padding:"20px"}}>      
                    <p>Este registro pedirá acesso momentâneo á:</p>
                    
                    {
                        /*
                     infos.tipo == 1 && <>
                    <div style={{display:"flex", gap:"15px", height:"40px", border:"1px solid gray", padding:"10px", borderRadius:"12px", alignItems:"center", justifyContent:"space-between"}}>
                        <div style={{display:"flex", gap:"15px", height:"100%", alignItems:"center", justifyContent:"start", width:"100%"}}>
                            <FaCamera size={23} /> Câmera</div>
                        { permiteCamera ? <div><strong style={{color:"green"}}>OK</strong></div> :
                          <div><strong style={{color:"red"}}>NECESSÁRIO PERMITIR</strong></div>}
                        
                    </div></> 
                    */}

                    <div style={{display:"flex", gap:"15px", height:"40px", border:"1px solid gray", padding:"10px", borderRadius:"12px", alignItems:"center", justifyContent:"space-between"}}>
                        <div style={{display:"flex", gap:"15px", height:"100%", alignItems:"center", justifyContent:"start", width:"100%"}}>
                            <GiPositionMarker size={23} /> Localização</div>

                        { permitePos ? <div><strong style={{color:"green"}}>OK</strong></div> :
                          <div><strong style={{color:"red"}}>NECESSÁRIO PERMITIR</strong></div>}
             
                    </div>
         {/*
(infos.tipo == 1 && permiteCamera) ? false :
(infos.tipo != 1 ? false : true)
         */}
                    
                    <BButton texto={"Próximo"} carregando={carregando} iconPos='end' fontSize='16px' disabled={ false }
                             icon={<GrNext size={16} />}  onClick={()=>{
                                setCarregando(true);
                                delay(()=>{
                                    setCarregando(false);
                                    setPage(1)
                                }, 3000);
                                
                                }}/>

                </div>
            </>}
            { page == 1 && <>
            <div className="pontoqr-box" style={{height:"auto"}}>      
                <div className='pontoqr-interno' style={{display:"flex", flexDirection:"column", gap:"15px", padding:"15px", height:"auto"}}>
                {infos && <div style={{width:"100%", height:"18px", textAlign:"center", fontSize:"16px"}}><strong>{infos.tipo_tx}</strong></div> }
                {infos && <div>NOME: <strong>{infos.nome}</strong></div> }
                {infos && <div>HORÁRIO: <strong>{infos.hora}</strong></div> }
                
                {infos.tipo == 1 && permiteCamera ? <>
                <div style={{height:"270px", width:"100%", padding:"15px"}}>
                <BButton texto={"Problemas na câmera? Tentar novamente."} width='100%' fontSize='14px' onClick={()=>{
                                setVerCamera(false);
                                toast.warn("Aguarde...")
                                delay( ()=> { 
                                    setVerCamera(true);
                                }, 3000)
                            }}/>  
                          {permiteCamera && <>  
                    <div style={{height:"100%", width:"100%", border:"1px solid gray", overflow:"hidden",
                                     borderRadius:"18px", position:"relative", display:"flex", alignItems:"center", justifyContent:"center"}}>
                            { screenshot ? <>
                                {screenshot && 
                                        <img className='flash-foto' id="img_tirada_24125" width="550" height="370" src={screenshot} alt="Screenshot" type="image/jpeg" /> }
                                <div style={{zIndex:999999, position:"absolute", top: "195px",  width:'50%', left:"8px"}}>
                                    <BButton icon={<FiRefreshCcw size={22} />} texto={"Refazer Foto"} width='100%' fontSize='15px' onClick={
                                        ()=>{ setScreenshot(null) }
                                    }/>
                                </div>
                            </> : <>
                        
                            { verCamera && <>
                                <Webcam 
                                    audio={false}
                                    //height={370}
                                    //width={550}
                                    ref={webcamRef}
                                    screenshotFormat="image/jpeg"
                            
                                    videoConstraints={videoConstraints}
                                ></Webcam>
                            </>}

                                <div style={{zIndex:999999, position:"absolute", top: "195px",  width:'90%', left:"17px"}}>
                                    <BButton icon={<TiCameraOutline size={25} />} texto={"Tirar Foto"} width='100%' fontSize='18px' onClick={captureScreenshot}/>
                                </div>
                            </>}

                               
                          
                           
                    </div></>}
                    
                </div>
                </> : <>Não será preciso foto neste registro.</>}
                <BButton texto={"Próximo"} iconPos='end' fontSize='16px' icon={<GrNext size={16} />}  onClick={()=>{setPage(2)}}/>

                </div>
            </div></> }

            { page == 2 && <>
                <div className="pontoqr-box" style={{height:"auto"}}>      
                <div className='pontoqr-interno' style={{display:"flex", flexDirection:"column", gap:"15px", padding:"15px", height:"auto"}}>
                {infos && <div style={{width:"100%", height:"18px", textAlign:"center", fontSize:"16px"}}><strong>{infos.tipo_tx}</strong></div> }
                <div style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                    <div style={{display:"flex", gap:"12px", flexDirection:"column"}}>
                        {infos && <div>NOME: <strong>{infos.nome}</strong></div> }
                        {infos && <div>HORÁRIO: <strong>{infos.hora}</strong></div> }
                    </div>
                    {screenshot && 
                    <div style={{overflow:"hidden", width:"100px", height:"60px", borderRadius:"18px", display:"flex",justifyContent:"center", alignItems:"center"}}>
                    <img id="img_tirada_24125" style={{height:"100px", width:"200px",}} src={screenshot} alt="Screenshot" type="image/jpeg" /></div>}
                          
                </div>

                     
                <BEdit titulo={"Observações"} setValue={setObs}  value={obs} hint={"Descreva as observações, caso tenha."}/>
             
                { infos.tipo == 1 ? <>
                    <BCheck setValue={(e)=> { setAceito(e) }} fontSize={20} value={aceito} fontSize={"14px"} titulo={"Estou em meu local de trabalho e minha foto foi tirada neste momento."} />
                </> : <>
                    <BCheck setValue={(e)=> { setAceito(e) }} fontSize={"20px"} value={aceito} fontSize={"14px"} titulo={"Estou em meu local de trabalho."} />   
                </>}
             
                <BPassw titulo={"Senha do Usuário"} setValue={setUPasse}  value={uPasse} onKeyEnter={()=>{    registrar() }}/>

                <div style={{display:"flex", gap:"8px"}}>

                
                <BButton texto={"Voltar"}  fontSize='16px' icon={<MdArrowBackIos size={16} />}  onClick={()=>{setPage(1)}}/>
{
    /* (screenshot || safeInt(infos.tipo) >= 2) */
}
                <BButton texto={"Registrar!"}  fontSize='16px' icon={<FaCheck size={16} />} carregando={carregando}
                        disabled={aceito && safeStr(uPasse).length > 2 ? false : true} width='100%' onClick={()=>{
                    
                            registrar()

                   
                }}/>
                </div>

                </div>
            </div>
            </>}

            { page == 3 && <>
                {infos && <div style={{width:"100%", height:"18px", textAlign:"center", fontSize:"16px"}}><strong>{infos.tipo_tx}</strong></div> }
                <div style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                    <div style={{display:"flex", gap:"12px", flexDirection:"column"}}>
                        {infos && <div>NOME: <strong>{infos.nome}</strong></div> }
                        {infos && <div>HORÁRIO: <strong>{infos.hora}</strong></div> }
                    </div>
                    {screenshot && 
                    <div style={{overflow:"hidden", width:"100px", height:"60px", borderRadius:"18px", display:"flex",justifyContent:"center", alignItems:"center"}}>
                    <img id="img_tirada_24125" style={{height:"100px", width:"200px",}} src={screenshot} alt="Screenshot" type="image/jpeg" /></div>}
                          
                </div>
                <div className="pontoqr-box" style={{height:"auto"}}>      
                      <p style={{fontSize:"22px", color:"red", padding:"15px"}}>Expirado</p>
                      <strong style={{fontSize:"22px", color:"red", padding:"15px"}}>{erroTx}</strong>
                </div>
            </>}

            { page == 4 && <>
                {infos && <div style={{width:"100%", height:"18px", textAlign:"center", display:"flex", alignItems:"center",  fontSize:"16px"}}><strong>{infos.tipo_tx}</strong></div> }
                <div style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                    <div style={{display:"flex", gap:"12px", flexDirection:"column"}}>
                        {infos && <div>NOME: <strong>{infos.nome}</strong></div> }
                        {infos && <div>HORÁRIO: <strong>{infos.hora}</strong></div> }
                    </div>
                    {screenshot && 
                    <div style={{overflow:"hidden", width:"100px", height:"60px", borderRadius:"18px", display:"flex",justifyContent:"center", alignItems:"center"}}>
                    <img id="img_tirada_24125" style={{height:"100px", width:"200px",}} src={screenshot} alt="Screenshot" type="image/jpeg" /></div>}
                          
                </div>
                <div className="pontoqr-box" style={{height:"200px", width:"350px", display:"flex", alignItems:"center", justifyContent:"center", flexDirection:"column", gap:"15px"}} >      
                <GiBackwardTime size={75} color='green'/>
                    <p style={{fontSize:"20px"}}>Registrado</p>
                </div>
            </>}
        </BSpinDiv>
    </>)
}