import { useEffect, useRef  } from 'react';
import './modal.css';
import { FiX } from 'react-icons/fi';
import { Spin } from 'antd';
import { BButton, BSpin } from '../BeiTec';
import { isMobile } from 'react-device-detect';
import { RiSave3Fill } from 'react-icons/ri';


export default function Modal({zindex=1000, semTitle=false, menu, menu_titulo, menu_width="250px", children, titulo, txFechar="Fechar", corFechar="#F65835", cor, 
                               btn_foco_name, btn_salvar_topo_tx="Salvar", com_escape=true, padding="5px",
                               carregando=false, blockFundo=true, click_close, btm_extra_click, btm_extra_tx="", btn_salvar_topo, 
                               btn_sim, btn_sim_cor, btn_sim_width="auto", btn_nao_cor, btn_nao, btns_div,
                               btn_sim_tx = "", btn_nao_tx = "", width="", height="auto", minHeight, }){
    let ref = useRef(null);

   
    const handleClickOutside = (event) => {
        if (event.target.id == "modal-fundo") {
         // alert(zindex);
          if(!carregando){
            if(!blockFundo){             
              closeDropdown();
            }
          }
        }
      };

      
      const presKey = (event) => {

        if(event.key === "Escape" && com_escape) {
          event.preventDefault();
          if(!carregando){
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', presKey, true);
             if(click_close) {
              click_close();
              console.log("BTN click_close");
             }else if(btn_nao){
              btn_nao();
              console.log("BTN NAO");
             }else if(btn_sim){
              btn_sim();
              console.log("BTN SIM");
             }          
            
          }
        }
      };

      useEffect(() => {  
        document.addEventListener('click', handleClickOutside, true);
        document.addEventListener('keydown', presKey, true);
        return () => {
          document.removeEventListener('click', handleClickOutside, true);
          document.removeEventListener('keydown', presKey, true);
        };
      },[]);


    const closeDropdown = event => {
            if(click_close){
              click_close();
            }
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', presKey, true);
      };

    return(<>
        <div className="modal" id="modal-fundo"  style={{}} >
            <div className="modal-container" style={{ width: width ? width : "560px", height: height ? height : "auto",  
                  minHeight : minHeight ? minHeight : 'none',
                  backgroundColor: cor ? cor : "white"}}>
                   
                { menu && <>
                  <div className="modal-menu-lateral"  style={{ minWidth:menu_width, width:menu_width}}>
                    { menu_titulo && <>
                    <div className="modal-titulo" style={{width:"100%", border:"none"}}>
                      <h2>{menu_titulo}</h2>
                    </div>
                    </>}
                    <div style={{width:"100%", height:"100%" ,padding: "9px" }}>
                    { menu }
                    </div>
                  </div>
                </>}
              <div style={{ height:"100%", width:"100%",display:"flex", flexDirection:"column" }}>
                <div className="modal-titulo" style={{width:"100%"}}>
                               
                  <h2  >{titulo}</h2>
                           
                  { btm_extra_click && btm_extra_tx && <>
                    <button 
                      className="extra_new" style={{marginRight:"10px", width:"auto"}}
                      onClick={ () => { btm_extra_click(); } }><div style={{width:"auto", whiteSpace: "nowrap"}}>{btm_extra_tx}</div>  
                    </button>
                  </> }

                  { btn_salvar_topo && <>
                    <button name={btn_foco_name ? btn_foco_name : null}
                      className="salva_new" style={{marginRight:"10px"}}
                      onClick={ () => { btn_salvar_topo(); } }><RiSave3Fill size={23} color="#1ca427" />{btn_salvar_topo_tx}
                    </button>
                  </> }

                  { txFechar != "-s" && click_close && <>
                  { /* 
                    <button 
                      className="close" style={{backgroundColor: corFechar}} 
                      onClick={ () => { closeDropdown(); } }><FiX size={23} color="#FFF" />{ !isMobile && txFechar}
                    </button>*/}
                     <button 
                      className="close_new" 
                      onClick={ () => { closeDropdown(); } }><FiX size={23} color="#a41c1c" />{ !isMobile && txFechar}
                    </button>
                    </>
                  }   
                </div>  
                <BSpin display={carregando}>                 
                  { 
                  // <div className="row" style={{display:"flex",  flexDirection:"column", height:"100%", alignItems:"center"}}>           
                  }
            
                  <div className="modal-content" style={{padding: padding}}>
                    {children}
                  </div>    
                  { (btn_sim || btn_nao || btns_div) && <>
                  <div className="modal-btns">   
                    {btn_sim_tx && (  
                      <BButton name={btn_foco_name ? btn_foco_name : null}
                        onClick={() => { btn_sim ? btn_sim() : closeDropdown() }} height='32px'  width={btn_sim_width} fontSize='14px'
                        texto={btn_sim_tx} borda={true} fundo={ btn_sim_cor ? btn_sim_cor : "green"} cor='white' />
                    ) }
    
                    {btn_nao_tx && (  
                      <BButton onClick={() => { btn_nao ? btn_nao() : closeDropdown() }} texto={btn_nao_tx} borda={true} 
                               fundo={ btn_nao_cor ? btn_nao_cor : "red"} height='32px'  width="auto" cor='white' fontSize='14px' />
                    ) }
                   { btns_div && <div style={{display:"flex", justifyContent:"space-between", alignItems:"center" ,width:"100%"}}>{btns_div}</div>}
                  </div>  
                  </> }     
                </BSpin>
              </div>          
            </div>
        </div>
    </>)
}