import React, {useEffect, useState,useContext} from 'react';
import './Main.css';

import { BAppContent, BButton } from '../../components/BeiTec';


export default function Main({children, name}){
    document.title = 'BeiTec';
      
    useEffect( () => {

    },[]);

    const itemInfos = (titulo, qtd, valor) => { 
        return(<>
                        <div className="dash_infos_item_fundo">                         
                            <div className="dash_infos_item">                  
                                <div className="dash_infos_titulo">
                                    {titulo}
                                </div>  
                                <div className="dash_infos_qtd">
                                    {qtd}
                                </div> 
                                <div className="dash_infos_valor">
                                   R$ {valor}
                                </div> 
                            </div>  
                        </div>  
        </>)
    }



function getLocation()
  {
  if (navigator.geolocation)
    {
    navigator.geolocation.getCurrentPosition(showPosition,showError);
    }
  else{
      alert("Geolocalização não é suportada nesse browser.");
    }
  }



function getDms(val) {
  var valDeg, valMin, valSec, result;

  val = Math.abs(val);

  valDeg = Math.floor(val);
  result = valDeg + "º";

  valMin = Math.floor((val - valDeg) * 60);
  result += valMin + "'";

  valSec = Math.round((val - valDeg - valMin / 60) * 3600 * 1000) / 1000;
  result += valSec + '"';

  return result;
}

// conversion string in DD to DMS.
function ddToDms(lat, lng) {
  var latResult, lngResult, dmsResult;

  lat = parseFloat(lat);
  lng = parseFloat(lng);

  latResult = (lat >= 0)? 'N' : '';
  // Call to getDms(lat) function for the coordinates of Latitude in DMS.
  // The result is stored in latResult variable.

  latResult += getDms(lat);
  lngResult = (lng >= 0)? 'L' : '';

  // Call to getDms(lng) function for the coordinates of Longitude in DMS.
  // The result is stored in lngResult variable.
  lngResult += getDms(lng);

 // document.getElementById("latMap").value = latResult;
 // document.getElementById("lngMap").value = lngResult;
 return String(latResult+"S+"+lngResult)
}


function showPosition(position)
  {
    let lat=position.coords.latitude;
    let lon=position.coords.longitude;

   // alert(JSON.stringify(position.coords.accuracy))
    let latlon = position.coords.latitude + "," + position.coords.longitude + ","+position.coords.accuracy;
    let graus = ddToDms(position.coords.latitude, position.coords.longitude);
   // 30°06'59.9"S+51°18'53.3"W 
   // 30º6'59.852"S+O51º18'52.502"W
   // 28°45'43.7"S+50°00'29.2"W
   //S30º6'59.854"S+O51º18'52.478"
   //setLinkMaps();

  // 30°07'12.8"S+51°18'56.1"W
  // 30°07'02.1"S+51°18'51.8"W
   window.open("https://www.google.com.br/maps/place/"+graus+"W/@"+latlon+"/", '_blank');
   //setLinkMaps(`https://www.google.com.br/maps/place/30°06'59.9"S+51°18'53.3"W`);

  }



function showError(error)
  {
  switch(error.code)
    {
    case error.PERMISSION_DENIED:
        alert("Usuário rejeitou a solicitação de Geolocalização.");
      break;
    case error.POSITION_UNAVAILABLE:
        alert("Localização indisponível.");
      break;
    case error.TIMEOUT:
        alert("O tempo da requisição expirou.");
      break;
    case error.UNKNOWN_ERROR:
        alert("Algum erro desconhecido aconteceu.");
      break;
    }
  }







    return(
    <>
        <BAppContent titulo={``} sub={""}>
       
        </BAppContent>
 
    </>
    )
}