import React, { useEffect } from "react";
import { createContext, useState, useContext } from "react";
import { toast } from "react-toastify";
import { safeInt, safeStr } from "./services/utils";

export const AppContext = createContext({});



export const AppPerm = ({code=""}) => {
    const { listApps } = useContext(AppContext);
    if(listApps.includes(code) ){
        return true
    }else{
        return false
    }
}


const ContextProvider = ({children}) => {
    const [ loading, setLoading] = useState(false);   
    const [ ctx_id_loja, set_ctx_id_loja] = useState(-1);   
    const [ menuAberto, setMenuAberto] = useState(false);   
    const [ viuAlertaCx, setViuAlertaCx ] = useState(false);   

    const [ isSimplesNacional, setIsSimplesNacional ] = useState(true);   
    
    const [user, setUser] = useState(localStorage.getItem("loja_user"));  
    
    const [ ctx_enc_status, set_ctx_enc_status ] = useState([]);
    const [ ctx_cad_grupos, set_ctx_cad_grupos ] = useState([]);

    const [ ctx_fretes, set_ctx_fretes ] = useState([]);
    const [ cx_menu_itens, set_cx_menu_itens ] = useState([]);
    const [ cx_menu_paginas, set_cx_menu_paginas ] = useState([]);

    const [ listApps, setListApps ] = useState([]);

    const [isMobile, setIsMobile] = useState(false);
    const [isTablet, setIsTablet] = useState(false);   
    const [isQuadrado, setIsQuadrado] = useState(false); 

    const [isOnline, setIsOnline] = useState(true); 

    const [hwidth, setHwidth] = useState(window.innerWidth);

    const [bemVindo_fechou, setBemVindo_fechou] = useState(false);   


    useEffect(() => {
        let n = safeStr(localStorage.getItem("loja_user"));
        if(localStorage.getItem("loja_user") != user){
            setUser(n);
        }
    }, [loading,menuAberto,cx_menu_itens]);

    
    useEffect(() => {


      }, [isOnline]);



    useEffect(() => {
        function handleResize() {
            setHwidth(window.innerWidth);
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
      }, [hwidth]);
  
    useEffect(() => {
        let temp_hwidth = hwidth;
        if( temp_hwidth < 620) { 
            if( !isMobile ){ setIsMobile(true) }   
            if( isTablet ){ setIsTablet(false) }  
        }else{ 
            if(isMobile){ setIsMobile(false) }           
            if( temp_hwidth < 890) {
                if(!isTablet){ setIsTablet(true) }
            }else{
                if( isTablet ){ setIsTablet(false) } 
                if( temp_hwidth < 1050) {
                    if(!isQuadrado){ setIsQuadrado(true) }
                }else{
                    if(isQuadrado){ setIsQuadrado(false) }  
                } 
            }
        }
       // toast.success(temp_hwidth)
    }, [hwidth]);
    
    // retorna o que esta dentro do app.jsx
    return (
        <AppContext.Provider value={{signed: !!user, loading, 
            user, setUser, menuAberto, setMenuAberto, 
            ctx_enc_status, set_ctx_enc_status,
            ctx_id_loja, set_ctx_id_loja, 
            ctx_cad_grupos, set_ctx_cad_grupos,
            ctx_fretes, set_ctx_fretes,
            cx_menu_itens, set_cx_menu_itens,
            cx_menu_paginas, set_cx_menu_paginas,
            isTablet, isMobile, isQuadrado, hwidth, 
            isOnline,setIsOnline, 
            listApps, setListApps, 
            viuAlertaCx, setViuAlertaCx,
            bemVindo_fechou, setBemVindo_fechou, 
            isSimplesNacional, setIsSimplesNacional,
        }}>
             {children}
        </AppContext.Provider>

    )
}

export default ContextProvider;

